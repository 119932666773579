import React, { Component } from "react";
import tapToSignIcon from "images/tap-to-sign-icon.svg";

import { Context } from "UtilityFunctions";
import { KioskTapToSign, Login } from "giro-kiosk-base";
import { Loading, Translation, TranslationContext } from "giro-react-toolkit";
import DateHelper from "giro-react-toolkit/source/DateHelper";
import { actions, connect } from "store";

/**
 * The first page to show while unlogged.
 * Handles the logic for showing the Login with username/password or by card.
 */
class HomePage extends Component {
   constructor(props) {
      super(props);

      this.loginWithUsernameAndPassword = this.loginWithUsernameAndPassword.bind(this);
   }

   /**
    * Logs in the user with a username and a password.
    * Only used with the Login component.
    *
    * @param {string} username - Username.
    * @param {string} password - Password.
    * @param {Date} loginTime - Date of login.
    */
   async loginWithUsernameAndPassword(username, password, loginTime) {
      const loginTimeISODateString = DateHelper.fromDateToISODateString(loginTime);

      await actions.logIn({
         formData: { username, password, loginTime: loginTimeISODateString, fromCard: false },
         translationContext: this.context,
      });
   }

   render() {
      return (
         <Loading centered isDisplayed={ this.props.isAuthenticating } scale="large">
            { this.props.loginMode.toLowerCase() === "swipe" ? (
               Context.isWebOnly() === false ? (
                  <KioskTapToSign svg={ tapToSignIcon } />
               ) : (
                  <div id="swipe-mode-not-supported-message">
                     <Translation resourceKey="HomePageSIG.swipeModeNotSupported" />
                  </div>
               )
            ) : (
               <Login onSuccessNextPage={ this.loginWithUsernameAndPassword } displayLoading={ false } />
            ) }
         </Loading>
      );
   }
}

HomePage.contextType = TranslationContext;

export default connect((state) => ({
   isAuthenticating: state.authentication.isAuthenticating,
   loginMode: state.configurations.data.LoginMode,
}))(HomePage);
