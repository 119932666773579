import React, { PureComponent } from "react";

import { Button, Icon, Loading, Popup, TimeConverter, Translation, TranslationContext } from "giro-react-toolkit";

import Acknowledge from "./Acknowledge";
import { httpPostWithAccessToken } from "UtilityFunctions";
import { actions, connect } from "store";

import "./EmployeeMessagesPage.scss";
import messageIcon from "images/message.svg";

/**
 * Message page displaying messages that must be force acknowledged.
 * Force acknowledged messages are not affected by EmployeeMessageAcknowledgmentMode configuration.
 */
class EmployeeMessagesPage extends PureComponent {
   constructor(props) {
      super(props);

      const filteredEmployeeMessages = props.employeeMessages.filter(x => x.ForceToAcknowledgeForSignInOut === true);

      this.state = {
         //defines whether popup is displayed
         showPopup: true,
         //state of the acknowledge checkbox value
         isCurrentMessageAcknowledged: false,
         //defines whether Continue button is disabled
         isContinueButtonDisabled: true,
         //current message displayed's index in the filteredEmployeeMessages array
         currentDisplayedEmployeeMessageIndex: 0,
         //current message displayed
         currentDisplayedEmployeeMessage: filteredEmployeeMessages[0],
         //replaces the content of the popup with a loading icon
         displayLoading: false,
         // Messages which are forced to be acknowledged.
         filteredEmployeeMessages
      };

      this.onCheckboxChange = this.onCheckboxChange.bind(this);
      this.onContinueButtonClick = this.onContinueButtonClick.bind(this);
   }

   /**
    * Function that enables the Continue button when the user has "read" the message
    */
   onCheckboxChange() {
      this.setState({
         isCurrentMessageAcknowledged: true,
         isContinueButtonDisabled: false
      });
   }

   /**
    * Function that finds the current message and sets its to acknowledged
    * @param {array} employeeMessages
    */
   setCurrentMessageToAcknowledge() {
      let currentMessage = this.state.filteredEmployeeMessages.find(
         x => x.Key.Identifier === this.state.currentDisplayedEmployeeMessage.Key.Identifier
      );
      currentMessage.MessageHasBeenAcknowledged = true;
   }

   /**
    * Function called on click of Continue button
    */
   onContinueButtonClick() {
      this.setState({ displayLoading: true });
      //set the message to acknowledged (to be displayed in sign in/out page)
      this.setCurrentMessageToAcknowledge();

      //send the messages acknowledged (httpPost) and go to next message or page
      const acknowledgeEmployeeMessagesPromise = () => {
         return httpPostWithAccessToken(
            "/OperationEmployeeServiceRest/AcknowledgeEmployeeMessages",
            {
               EmployeeIdentifier: this.props.employeeId,
               EmployeeMessagesToAcknowledgeArray: [this.state.currentDisplayedEmployeeMessage.Key]
            },
            { signal: this.props.abortControllerSignal },
            this.context,
            { 
               actions, 
               authentication: this.props.stateAuthentication, 
               configurations: this.props.stateConfigurations,
               signConfig: this.props.signConfig
            }
         );
      };

      acknowledgeEmployeeMessagesPromise().then(() => {
         // Checks if we are in the last message to present to the user and prepares the values
         // for the state depending on the situation.
         if (this.state.currentDisplayedEmployeeMessageIndex != this.state.filteredEmployeeMessages.length - 1) {
            //update current message index and disable continue button
            this.setState(
               prevState => ({
                  currentDisplayedEmployeeMessage: this.state.filteredEmployeeMessages[
                     prevState.currentDisplayedEmployeeMessageIndex + 1
                  ],
                  currentDisplayedEmployeeMessageIndex: this.state.currentDisplayedEmployeeMessageIndex + 1,
                  isCurrentMessageAcknowledged: false,
                  isContinueButtonDisabled: true,
                  displayLoading: false
               }),
               actions.resetSessionTimeout
            );
         } else {
            this.props.history.push(this.props.location.state.signChoice);
         }
      });
   }

   render() {
      return (
         <Popup
            className="employee-messages-page"
            isDisplayed={ true }
            showCloseButton={ false }
            shouldCloseOnEnter={ false }
            shouldCloseOnEsc={ false }
            shouldCloseOnOverlayClick={ false }
            onClose={ () => {} }
         >
            <div className="employee-messages-page__content">
               <Loading centered scale={ "large" } isDisplayed={ this.state.displayLoading }>
                  <div className="employee-messages-page__title">
                     <Icon path={ messageIcon } color="color-primary" className="employee-messages-page__title-icon" />
                     <Translation
                        resourceKey="EmployeeMessagesPageSIG.forceAcknowledge.title"
                        params={ [
                           // Name.
                           this.props.employeeName,
                           // Time.
                           TimeConverter.formatISODateStringToShortTimeString(
                              this.props.loginTime,
                              this.context.localizeValue
                           )
                        ] }
                     />
                  </div>

                  <div className="employee-messages-page__box">
                     <div className="employee-messages-page__message">
                        { this.state.currentDisplayedEmployeeMessage.MessageText }
                     </div>
                     <div className="employee-messages-page__box-bottom">
                        <Acknowledge
                           buttonText={
                              <Translation 
                                 resourceKey="EmployeeMessagesPageSIG.forceAcknowledge.acknowledgeButtonText" />
                           }
                           checkedText={
                              <Translation 
                                 resourceKey="EmployeeMessagesPageSIG.forceAcknowledge.acknowledgeCheckedText" />
                           }
                           className="employee-messages-page__acknowledge"
                           value={ this.state.isCurrentMessageAcknowledged }
                           onClick={ this.onCheckboxChange }
                        />
                        <div className="employee-messages-page__number">
                           { this.state.currentDisplayedEmployeeMessageIndex + 1 }/
                           { this.state.filteredEmployeeMessages.length }
                        </div>
                     </div>
                  </div>

                  <div className="employee-messages-page__buttons">
                     <Button
                        accent="primary"
                        scale="extra-large"
                        disabled={ this.state.isContinueButtonDisabled }
                        onClick={ this.onContinueButtonClick }
                     >
                        <Translation resourceKey="EmployeeMessagesPageSIG.forceAcknowledge.continueButtonLabel" />
                     </Button>
                     <Button accent="secondary" scale="extra-large" onClick={ () => actions.logOut() }>
                        <Translation resourceKey="GlobalSIG.cancelButtonLabel" />
                     </Button>
                  </div>
               </Loading>
            </div>
         </Popup>
      );
   }
}

EmployeeMessagesPage.contextType = TranslationContext;

export default connect(state => ({
   abortControllerSignal: state.application.abortController.signal,
   employeeId: state.employeeInfo.Identifier,
   employeeMessages: state.employeeMessages,
   employeeName: state.employeeInfo.DisplayName,
   loginTime: state.authentication.loginTime,
   stateConfigurations: state.configurations,
   stateAuthentication: state.authentication,
   signConfig: state.signConfig
}))(EmployeeMessagesPage);
