import React, { PureComponent } from "react";

import { Icon, Loading, TimeConverter, Translation, TranslationContext } from "giro-react-toolkit";

import MessagePanel from "Components/MessagePanel";
import Pieces from "Components/Pieces";
import { Context, httpPostWithAccessToken } from "UtilityFunctions";
import { actions, connect } from "store";

import "./SignInNonInteractive.scss";
import checkedIcon from "images/checkbox-checked-icon.svg";
import cardIcon from "images/simple-card.svg";
import notificationIcon from "images/notification-icon.svg";
import blackCircleIcon from "images/black-circle-icon.svg";

/**
 * Non-interactive sign in page
 */
class SignInNonInteractive extends PureComponent {
   constructor(props) {
      super(props);
      this.state = {
         // Determines whether loading is displayed.
         displayLoading: true,
         // Hides the employee messages section.
         hideEmployeeMessages: false,
         // Indicates if there's an overflow in the piece section.
         piecesOverflow: false,
         // List of service messages (stays null if service messages don't exist).
         serviceMessages: null,
         // Sign in information. null when the sign in is not yet complete.
         signInInfo: null,
      };
      //these refs are used to compare heights to check if there's any overflow
      this.piecesSection = React.createRef();
      this.piecesWrap = React.createRef();
   }

   componentDidMount() {
      const signInAndGetInformationPromise = () => {
         return httpPostWithAccessToken(
            "/OperationEmployeeServiceRest/SignInAndGetInformation",
            {
               EmployeeIdentifier: this.props.employeeId,
               SignDateTime: this.props.loginTime,
               SignSiteIdentifier: this.props.signInSiteIdentifier,
               SourceOfSign: "Sign",
            },
            { signal: this.props.abortControllerSignal },
            this.context,
            {
               actions, 
               authentication: this.props.stateAuthentication, 
               configurations: this.props.stateConfigurations,
               signConfig: this.props.signConfig
            }
         );
      };

      signInAndGetInformationPromise().then((json) => {
         const receiptContent = json.EmployeeReceiptText + json.ReceiptContentText;

         if (
            json.SignInInvalidCode &&
            json.SignInInvalidCode !== "AlreadySignedIn" &&
            json.SignInInvalidCode !== "SignInInfo"
         ) {
            !Context.isWebOnly() && webBrowserHostScriptingObject.printInvalidSignInText(receiptContent);

            actions.setErrorMessage({ errorMessage: json.SignInInvalidMessage });
         } else {
            !Context.isWebOnly() && webBrowserHostScriptingObject.printValidSignInText(receiptContent);

            this.setState(
               {
                  signInInfo: json,
                  serviceMessages:
                     json.SignInInformation && json.SignInInformation.ServiceMessages
                        ? json.SignInInformation.ServiceMessages
                        : [],
                  displayLoading: false,
               },
               () => {
                  let that = this;
                  // Checks if the piece section is overflowing.
                  // Les pièces ne sont pas encore dessinées lorsque ce callback est appelé.
                  // Un timeout est donc ajouté pour attendre qu'elles soient dessinées.
                  setTimeout(function () {
                     if (that.piecesSection.current.clientHeight > that.piecesWrap.current.clientHeight) {
                        that.setState({ piecesOverflow: true });
                     }
                  }, 100);
               }
            );

            if (json.SignInInvalidCode === "AlreadySignedIn" || json.SignInInvalidCode === "SignInInfo") {
               actions.setHeaderMessage(json.SignInInvalidMessage);
            }
         }
      });
   }

   render() {
      return (
         <div className="sign-in-non-interactive__content">
            <div ref={ this.piecesWrap } className="sign-in-non-interactive__hide">
               <div ref={ this.piecesSection } className="sign-in-non-interactive__piece-section">
                  <Loading isFullScreen isDisplayed={ this.state.displayLoading }>
                     <div className="sign-in-non-interactive__title-section">
                        <div className="sign-in-non-interactive__title-section-left">
                           <Icon
                              path={ checkedIcon }
                              color="color-primary"
                              className="sign-in-non-interactive__title-section-icon"
                           />
                           <span>
                              { this.context.localizeValue("SignInPageSIG.signInTimeTitle", [
                                 /*time:*/ TimeConverter.formatISODateStringToShortTimeString(
                                    this.props.loginTime,
                                    this.context.localizeValue
                                 ),
                              ]) }
                           </span>
                        </div>
                     </div>

                     <div className="sign-in-non-interactive__grey-line">
                        <div className="sign-in-non-interactive__pieces sign-in-non-interactive__pieces-signed">
                           <Pieces
                              displayContext="SignIn"
                              listData={
                                 this.state.signInInfo && this.state.signInInfo.SignInInformation
                                    ? this.state.signInInfo.SignInInformation.RemainingWorkPieces
                                    : []
                              }
                           />
                        </div>
                     </div>

                     <div className="sign-in-non-interactive__title-section">
                        { this.state.signInInfo && this.state.signInInfo.NextExpectedSignInDateTime ? (
                           <div
                              className="sign-in-non-interactive__title-section-left
                                           sign-in-non-interactive__title-section-later"
                           >
                              <Icon
                                 path={ cardIcon }
                                 color="grey-dark"
                                 className="sign-in-non-interactive__title-section-icon"
                              />
                              <span>
                                 { this.context.localizeValue("SignInPageSIG.nextSignInTimeTitle", [
                                    /*time:*/ TimeConverter.formatISODateStringToShortTimeString(
                                       this.state.signInInfo.NextExpectedSignInDateTime,
                                       this.context.localizeValue
                                    ),
                                 ]) }
                              </span>
                           </div>
                        ) : (
                           <div className="sign-in-non-interactive__title-section-left">
                              <Icon
                                 path={ blackCircleIcon }
                                 color="black"
                                 className="sign-in-non-interactive__title-section-icon--circle"
                              />
                           </div>
                        ) }
                     </div>
                  </Loading>
               </div>
               { this.state.piecesOverflow && (
                  <div className="sign-in-non-interactive__overflow">
                     <Icon path={ notificationIcon } color="white" className="sign-in-non-interactive__overflow-icon" />
                     <span className="sign-in-non-interactive__overflow-text">
                        <Translation resourceKey="SignInPageSIG.overflowPiecesText" />
                     </span>
                  </div>
               ) }
            </div>
            <MessagePanel serviceMessages={ this.state.serviceMessages } />
         </div>
      );
   }
}

SignInNonInteractive.contextType = TranslationContext;

export default connect((state) => ({
   abortControllerSignal: state.application.abortController.signal,
   displayPrintDTCButton: state.configurations.data.General.DisplayPrintDTCButton,
   employeeId: state.employeeInfo.Identifier,
   isDailyVehicleAvailable: state.configurations.isDailyVehicleAvailable,
   isDelayCreationEnabled: state.configurations.data.SignOutEnableDelayCreation,
   loginTime: state.authentication.loginTime,
   signInSiteIdentifier: state.configurations.data.SignInSiteIdentifier,
   webServicesUrl: state.configurations.data.General.WebServicesUrl,
   stateConfigurations: state.configurations,
   stateAuthentication: state.authentication,
   signConfig: state.signConfig
}))(SignInNonInteractive);
