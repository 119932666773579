import React, { Component } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { Icon, Loading, RollingMessage, TimeConverter, TranslationContext } from "giro-react-toolkit";
import { Error, KioskFooter, KioskHeader, ReadCardIdentifier } from "giro-kiosk-base";
import DateHelper from "giro-react-toolkit/source/DateHelper";

import ChoicePage from "Pages/ChoicePage";
import EmployeeMessagesPage from "Pages/EmployeeMessagesPage";
import HomePage from "Pages/HomePage";
import SignInPage from "Pages/SignInPage";
import SignInNonInteractive from "Pages/SignInPage/SignInNonInteractive";
import SignOutPage from "Pages/SignOutPage";
import { Context } from "UtilityFunctions";
import { actions, connect } from "store";

import "./App.scss";
import signIcon from "images/sign-employee-logo.svg";

/**
 * Only used for development.
 * Use the files in files/signKiosk for production.
 */
import clientLogo from "images/clientLogo.svg";
import clientTheme from "styles/clientTheme.css";

class App extends Component {
   constructor(props) {
      super(props);

      this.getKioskHeaderUserMessage = this.getKioskHeaderUserMessage.bind(this);
      this.onKeyDown = this.onKeyDown.bind(this);
      this.onTerminationRequested = this.onTerminationRequested.bind(this);
      this.state = {
         // Indicates if all the required data for the application is loaded, including the
         // configurations and translations.
         isInitializationComplete: false
      };
      this.loginWithCard = this.loginWithCard.bind(this);
   }

   componentDidMount() {
      this.addClientThemeToHead();
      document.addEventListener("keydown", this.onKeyDown);
      this.initializeComponent();
   }

   componentWillUnmount() {
      document.removeEventListener("keydown", this.onKeyDown);
   }

   /**
    * Adds the client theme css file to head element.
    */
   addClientThemeToHead() {
      if (document.getElementById("client-theme") == null) {
         const stylesheetLink = document.createElement("link");
         stylesheetLink.id = "client-theme";
         stylesheetLink.rel = "stylesheet";
         stylesheetLink.type = "text/css";
         stylesheetLink.href = Context.isWebOnly() ? clientTheme : "externals/styles/clientTheme.css";
         document.head.appendChild(stylesheetLink);
      }
   }

   /*
    * Handles closing the error popup.
    */
   handleCloseError() {
      if (this.props.errorMessageWillLogOut) {
         // Log out and clear all state.
         actions.logOut();
         // If the initialization is not done, try again
         if (!this.state.isInitializationComplete) {
            this.initializeComponent();
         }
      } else {
         // Removes the error message for the store since it was handled
         actions.clearErrorMessage();
      }
   }

   /**
    * Displays the client logo.
    **/
   displayClientLogo() {
      if (!Context.isWebOnly()) {
         return <img src={ "externals/images/clientLogo.svg" } />;
      } else {
         return <img src={ clientLogo } />;
      }
   }

   /**
    * Function that returns the duration time in milliseconds according to the path
    * or if there's an error.
    * Used in the timerBar of the footer.
    * @returns {number} - Timeout of the path (in milliseconds)
    */
   getFooterTimeoutDuration() {
      // First step is to check the existence of an error,
      // since it can happen in all the path values.
      if (this.props.errorMessage) {
         return this.props.errorDisplayTimeout;
      }
      switch (this.props.location.pathname) {
         case "/choice":
            return this.props.signChoiceDisplayTimeout;
         case "/employeemessages":
            return this.props.employeeMessageDisplayTimeout;
         case "/signin":
            return this.props.validDisplayTimeout;
         case "/signout":
            return this.props.validDisplayTimeout;
         case "/signout/delay":
            return this.props.serviceDelayDisplayTimeout;
         default:
            return this.props.errorDisplayTimeout;
      }
   }

   /**
    * Gets the user message to show to the employee in the KioskHeader.
    * or the sign time when signed in or out.
    * @return {string} - Localized message.
    */
   getKioskHeaderUserMessage() {
      if (this.props.headerMessage) {
         return this.props.headerMessage;
      }

      return this.context.localizeValue("GlobalSIG.header.signedAtText", [
         /*time:*/ TimeConverter.formatISODateStringToShortTimeString(this.props.loginTime, this.context.localizeValue),
      ]);
   }

   /**
    * Intialize the basic information for the application (configurations and translations) in the store.
    */
   async initializeComponent() {
      const currentContext = this.context;
      await actions.getSignConfigurations();
      if (this.props.errorMessage) {
         return;
      } 

      // Fetch the configurations from the web service based on the kiosk ID.
      await actions.getWebServicesConfigurations(currentContext);
      if (this.props.errorMessage) {
         return;
      } 
         
      // The configuration must be fetched before getting the translations, we need to know for
      // which language to get the translations.
      await actions.getConfigurations(currentContext);
      if (this.props.errorMessage) {
         return;
      } 

      await actions.getRepositoryInformation({
         translationContext: currentContext,
         // Loads the translations for Sign, the giro-kiosk-base and the giro-react-toolkit
         modulesToLoad: ["giro.sig", "grtk", "gkb"],
      });
      if (this.props.errorMessage) {
         return;
      } 

      // Update the context with the current culture and translations if translations are returned
      if (Object.keys(this.props.translations).length > 0) {
         currentContext.changeCulture(this.props.defaultCulture);
         currentContext.addTranslations(this.props.translations);
         this.setState({ isInitializationComplete: true });
      }
   }

   /**
    * Logs in the user using the idenfitier read by the card.
    *
    * @param {string} cardIdentifier - Employee card identifier.
    */
   async loginWithCard(cardIdentifier) {
      actions.logOut();

      const loginTime = DateHelper.fromDateToISODateString(new Date());
      const encryptedCardIdentifier = await webBrowserHostScriptingObject.encryptCardIdentifier(cardIdentifier);

      await actions.logIn({
         formData: { username: encryptedCardIdentifier, password: null, loginTime, fromCard: true },
         translationContext: this.context,
      });
   }

   /**
    * Handles keydown event.
    * @param {Object} e - KeyboardEvent object.
    */
   onKeyDown(e) {
      if (e.key === "F12" && !Context.isWebOnly()) {
         webBrowserHostScriptingObject.openDevTool();
      }
   }

   /**
    * Close sign kiosk.
    */
   onTerminationRequested() {
      if (Context.isWebOnly()) {
         // Rien à faire si les pages web ne sont pas affichées dans un kiosque.
         return;
      }

      webBrowserHostScriptingObject.onTerminationRequested(null);
   }

   /**
    * Display the error set in the store.
    * Exceptions thrown during the render are caught by the SignErrorBoundary instead.
    */
   renderError() {
      return (
         this.props.errorMessage && (
            <Error
               errorMessage={ this.props.errorMessage }
               closeButtonText={ this.props.closeButtonText }
               onClose={ () => {
                  this.handleCloseError();
               } }
               // Redundant in SignKiosk since we handle ourselves when to log out on close.
               // We might only want to show a warning and not force a log out.
               redirectToRoot={ false }
            />
         )
      );
   }

   /**
    * Renders the footer of the application.
    */
   renderFooter() {
      // If the initialization is not complete, the footer cannot be displayed since it needs the
      // current culture.
      if (!this.state.isInitializationComplete) {
         return <footer></footer>;
      }

      // Conditions to render ReadCardIdentifier. The card reader should be available
      // in every page in order to log out the previous user if a card is swiped.
      const shouldRenderReadCardIdentifier =
         this.props.loginMode.toLowerCase() === "swipe" && Context.isWebOnly() === false;

      return (
         <footer>
            { shouldRenderReadCardIdentifier && (
               <ReadCardIdentifier
                  startKeySequence={ this.props.employeeInputStartKeySequence }
                  endKeySequence={ this.props.employeeInputEndKeySequence }
                  onReadCompleted={ this.loginWithCard }
               />
            ) }
            { this.props.isLoggedIn || this.props.errorMessage ? (
               /** Only show KioskFooter when logged in. */
               <KioskFooter
                  history={ this.props.history }
                  onExit={ actions.logOut }
                  onTimerEnd={ actions.logOut }
                  // showExitButton is always false if the errorMessage has a value.
                  // showExitButton is true if SignInInteractiveMode is true
                  // since SignOutPage is inaccessible if SignInInteractiveMode is false.
                  showExitButton={ this.props.errorMessage ? false : this.props.isSignInInteractive }
                  showTimer
                  // Forces an instance change when the timeout should reset.
                  key={ this.props.location.pathname + this.props.footerKeyHash }
                  timerDuration={ this.getFooterTimeoutDuration() }
               />
            ) : (
               this.props.corporateMessages &&
               this.props.corporateMessages.length > 0 && (
                  <RollingMessage
                     arrayOfMessages={ this.props.corporateMessages }
                     className={ "footer__corporate-messages" }
                  />
               )
            ) }
         </footer>
      );
   }

   /**
    * Renders the header of the application.
    */
   renderHeader() {
      // Show KioskHeader if logged in and signed, otherwise show the logo with the title.
      const showKioskHeader = this.props.isLoggedIn && !["/choice", "/all"].includes(this.props.location.pathname);

      return (
         <header>
            { showKioskHeader ? (
               <KioskHeader
                  className={ "header__kiosk-header" }
                  userName={ this.props.employeeName }
                  userPicture={ this.props.employeePhoto }
                  userMsg={ this.getKioskHeaderUserMessage() }
               />
            ) : (
               <div className={ "header__content header__sign-logo" }>
                  <Icon path={ signIcon } color={ "color-primary" } className={ "header__sign-logo-icon" } />
                  SIGN <div className={ "header__sign-logo-kiosk" }>{ "KIOSK" }</div>
               </div>
            ) }
            <div className={ "header__content header__company-logo" }>{ this.displayClientLogo() }</div>
         </header>
      );
   }

   /**
    Renders the main content of the application.
    */
   renderContent() {
      // Force sign in mode when sign in is not interactive.
      const signMode = this.props.isSignInInteractive ? this.props.signMode.toLowerCase() : "/signin";

      let mustAcknowledgeMessages = false;
      if (this.props.isLoggedIn && signMode !== "all") {
         // Employee messages are loaded after the login.
         mustAcknowledgeMessages = this.props.employeeMessages.some(
            (x) => x.ForceToAcknowledgeForSignInOut === true && !x.MessageHasBeenAcknowledged
         );
      }

      if (!this.props.isLoggedIn) {
         return (
            /**
             * When employeeInfo is not loaded, we only consider a route to the HomePage.
             * Everything else redirects to the HomePage.
             */
            <Switch>
               <Route exact path={ "/" } component={ HomePage } />
               <Route render={ () => <Redirect to={ "/" } /> } />
            </Switch>
         );
      }

      const signInPage = this.props.isSignInInteractive ? SignInPage : SignInNonInteractive;
      return (
         /**
          * When authenticated and employeeInfo is loaded, set the routes.
          * Every other route redirects to the signMode in the configurations
          * or isSignInInteractive which overrides the signMode.
          */
         <Switch>
            <Route path={ "/choice" } component={ ChoicePage } />
            <Route path={ "/signin" } component={ signInPage } />
            <Route path={ "/signout" } component={ SignOutPage } />
            <Route path={ "/employeemessages" } component={ EmployeeMessagesPage } />

            <Route path={ "/all" } render={ () => <Redirect to={ "/choice" } /> } />
            <Route
               render={ () =>
                  mustAcknowledgeMessages && signMode !== "all" ? (
                     // Redirect to /employeemessages page when the sign mode is sign_in or sign_out and
                     // the employee must acknowledge employee messages.
                     // The ChoicePage after the choice makes the redirection when the sign mode is all.
                     // The sign choice is defined by the sign mode (sign in or sign out) when it is not all.
                     <Redirect
                        to={ {
                           pathname: "/employeemessages",
                           state: { signChoice: signMode },
                        } }
                     />
                  ) : (
                     <Redirect to={ signMode } />
                  )
               }
            />
         </Switch>
      );
   }

   render() {
      return (
         <Loading
            isFullScreen
            isDisplayed={ !this.state.isInitializationComplete && !this.props.errorMessage }
            scale="extra-large"
         >
            <div className={ "app__container" }>
               { this.addClientThemeToHead() }
               { this.renderHeader() }
               <main>
                  {
                     // Keep the content on screen, even if there is an error, so the content stays
                     // rendered and the user can go back to it after the error is closed.
                     this.state.isInitializationComplete && this.renderContent()
                  }

                  { this.props.errorMessage && this.renderError() }
               </main>
               { this.renderFooter() }
            </div>
         </Loading>
      );
   }
}

App.contextType = TranslationContext;

export default connect((state) => ({
   /** Application global states and objects. */
   corporateMessages: state.application.corporateMessages,
   errorMessage: state.application.errorMessage,
   errorMessageWillLogOut: state.application.errorMessageWillLogOut,
   headerMessage: state.application.headerMessage,
   footerKeyHash: state.application.footerKeyHash,

   /** General configurations. */
   areConfigurationsLoaded: state.configurations.isLoaded,
   isSignInInteractive: state.configurations.data.SignInInteractiveMode,
   loginMode: state.configurations.data.LoginMode,
   signMode: state.configurations.data.SignMode,
   defaultCulture: state.configurations.data.General.DefaultCulture,
   translations: state.translations.data,
   closeButtonText: state.configurations.data.General.CloseButtonText,
   employeeInputEndKeySequence: state.configurations.data.EmployeeInputEndKeySequence,
   employeeInputStartKeySequence: state.configurations.data.EmployeeInputStartKeySequence,
   kioskId: state.signConfig.data.kioskId,

   /** Footer timeouts. */
   employeeMessageDisplayTimeout: state.configurations.data.EmployeeMessageDisplayTimeout * 1000,
   errorDisplayTimeout: state.configurations.data.ErrorDisplayTimeout * 1000,
   serviceDelayDisplayTimeout: state.configurations.data.SignOutServiceDelayDisplayTimeout * 1000,
   signChoiceDisplayTimeout: state.configurations.data.SignChoiceDisplayTimeout * 1000,
   validDisplayTimeout: state.configurations.data.ValidDisplayTimeout * 1000,

   /** Authentication and logged in user. */
   employeeMessages: state.employeeMessages,
   employeeName: state.employeeInfo.DisplayName,
   employeePhoto: state.employeeInfo.Photo,
   isLoggedIn: state.authentication.isLoggedIn,
   loginTime: state.authentication.loginTime,
}))(App);
