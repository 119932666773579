import React, { Component } from "react";
import PropTypes from "prop-types";
import ClassNames from "classnames";

import { Button } from "giro-react-toolkit";

import "./Acknowledge.scss";
import checkedIcon from "images/checkbox-checked.svg";

/**
 * Checkbox displayed as a button when it's not checked.
 * On click/check, it displays a message to acknowledge the change.
 */
class Acknowledge extends Component {
   constructor(props) {
      super(props);
      this.state = {
         checked: this.props.value
      };
   }

   componentDidUpdate() {
      /** updates the state only if value has changed */
      if (this.state.checked !== this.props.value) {
         this.setState({ checked: this.props.value });
      }
   }

   render() {
      const className = ClassNames("acknowledge", this.props.className);
      return (
         <div className={ className }>
            { this.state.checked ? (
               <div className="acknowledge__checked">
                  <img src={ checkedIcon } />
                  <span>{ this.props.checkedText }</span>
               </div>
            ) : (
               <Button
                  accent="primary"
                  scale="extra-large"
                  onClick={ e => {
                     this.props.onClick(e);
                  } }
               >
                  { this.props.buttonText }
               </Button>
            ) }
         </div>
      );
   }
}

Acknowledge.propTypes = {
   /** CSS classname added to the component */
   className: PropTypes.string,
   /** Function called on button click, should update the value props.  */
   onClick: PropTypes.func,
   /** Determines the state (checked) of the item. */
   value: PropTypes.bool,
   /** Text displayed when checked. */
   checkedText: PropTypes.element,
   /** Text displayed in the button. */
   buttonText: PropTypes.element
};

export default Acknowledge;
