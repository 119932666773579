import { httpGetWithAccessToken } from "UtilityFunctions";

/**
 * Actions relative to queries to the repository service
 */
const RepositoryServiceActions = {
   /**
    * Gets the fieldset information for all the fieldsets in fieldsetsToLoad for the specified
    * object and the current culture.
    * Automatically called when application starts.
    */
   getFieldsets: async (
      { application, authentication, configurations, fieldsets, signConfig },
      actions,
      { translationContext, object, fieldsetsToLoad }
   ) => {
      if (fieldsets.isLoaded) {
         return;
      }

      let data = {};
      fieldsetsToLoad.forEach(async (fieldsetName) => {
         const fieldset = await httpGetWithAccessToken(
            `/OperationRepositoryServiceRest/FieldSet/ObjectTypeName/${object}/FieldSetName/${fieldsetName}`,
            null,
            { signal: application.abortController.signal },
            translationContext,
            { actions, authentication, configurations, signConfig },
            true /*excludeAccessToken*/
         );
         data[fieldsetName] = fieldset.Fields;
      });

      if (Object.keys(data).length != fieldsetsToLoad.lenght) {
         // If not all the fieldsets are present in the data, it means that they are not completly loaaded.
         return {
            fieldsets: {
               data,
               isLoaded: false,
            },
         };
      }

      return {
         fieldsets: {
            data,
            isLoaded: true,
         },
      };
   },

   /**
    * Gets the specified translations for the current culture.
    * Automatically called when application starts by App.js.
    */
   getTranslations: async (
      { application, authentication, configurations, translations, signConfig },
      actions,
      { translationContext, modulesToLoad }
   ) => {
      if (translations.isLoaded) {
         return;
      }

      let data = {};
      for (let i = 0; i < modulesToLoad.length; i++) {
         const translation = await httpGetWithAccessToken(
            `/OperationRepositoryServiceRest/Resource/Module/${modulesToLoad[i]}`,
            null,
            { signal: application.abortController.signal },
            translationContext,
            { actions, authentication, configurations, signConfig },
            true /*excludeAccessToken*/
         );
         const translationJson = JSON.parse(translation);
         data = { ...data, ...translationJson[modulesToLoad[i]] };
      }

      if (Object.keys(data).length !== 0) {
         // If no data, it means that no translation was loaded
         return {
            translations: {
               data,
               isLoaded: false,
            },
         };
      }

      return {
         translations: {
            data,
            isLoaded: true,
         },
      };
   },

   /**
    * This is only done once when starting the application
    * Gets the following information:
    * - Translations for the current culture
    * - Fieldsets for the current culture
    */
   getRepositoryInformation: async (state, actions, { translationContext, modulesToLoad }) =>
      Promise.all([
         actions.getTranslations({ translationContext, modulesToLoad }),
         actions.getFieldsets({
            translationContext,
            object: "DailyPiece",
            fieldsetsToLoad: [
               "SignInRow1",
               "SignInRow2",
               "SignOutRow1",
               "SignOutRow2",
               "SignOutDelayRow1",
               "SignOutDelayRow2",
            ],
         }),
      ]),
};

export default RepositoryServiceActions;
