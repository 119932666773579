import { httpGet, httpPost, promiseTimeout } from "giro-react-toolkit";
import { TRACE_LEVEL } from "./Constants";

/**
 * Adds the Authorization header to the HTTP request options.
 * @param {Object} options - HTTP request options.
 * @param {Object} options - Token object.
 * @returns {Object} HTTP request options with Authorization header.
 */
function addAccessToken(options, { authentication, configurations }) {
   // Add token option.
   if (!options) {
      options = {};
   }

   if (!options.headers) {
      options.headers = {};
   }

   options.headers["Authorization"] = authentication.token.token_type + " " + authentication.token.access_token;
   options.headers["Cache-Control"] = "no-cache";
   options.headers["Accept-Language"] = configurations.data.General.DefaultCulture;

   return options;
}

/**
 * Handles exceptions thrown by Fetch.
 * @param {string} error - The error message returned by the exception, if any.
 * @param {string} url - The url that was called to case the error.
 * @param {Object} actions - The actions from the data store.
 * @param {Object} configuration - Configurations to get the translated text (not available from the translation).
 */
function handleFetchException(error, url, actions, configurations) {
   if (error) {
      console.error(error);
   }
   console.error(
      `The Sign kiosk is running, but is unable to reach the web service method at the url: ${url}.
       Verify the configurations and SSL certificates of the pooler, web services and security token server.`
   );

   actions.setErrorMessage({
      errorMessage: configurations.data.General.BackendGeneralErrorText,
      errorMessageWillLogOut: true,
   });
}

/**
 * Will be fired when a promise timeout before resolving.
 * @param {string} message - The error message to display in the console.
 * @param {Object} errorInfo - An object containing information about the promise that caused the error.
 * @param {Object} translationContext - TranslationContext to localize text.
 */
function handleHttpTimeout(message, errorInfo, translationContext, actions) {
   actions.setErrorMessage({ errorMessage: translationContext.localizeValue("HttpHandlersSIG.backendError.timeout") });
   actions.trace(TRACE_LEVEL.error, message, JSON.stringify(errorInfo));
}

/**
 * Will be fired whenever an ajax calls returns an http code different than OK (2XX).
 * @param {Promise} httpResponse - The HTTP response returned by the fetch.
 * @param {Object} jsonResponseBody - The body of the response to the request that returned the error in JSON format.
 * @param {Object} fetchOptions - The fetch options of the request that caused the error.
 * @param {Object} translationContext - TranslationContext to localize text.
 */
export function httpErrorHandler(
   httpResponse,
   jsonResponseBody,
   fetchOptions,
   translationContext,
   { actions, configurations }
) {
   let message;
   let errorMessageWillLogOut = true;

   // Faults from the web services return a message in .Message
   if (jsonResponseBody) {
      message = jsonResponseBody.Message;
   }

   // The STS returns the following object on error
   // https://www.oauth.com/oauth2-servers/access-tokens/access-token-response/.
   // invalid_grant is returned instead of invalid_client on invalid username/password
   if (jsonResponseBody && jsonResponseBody.error) {
      message = translationContext.localizeValue(
         "HttpHandlersSIG.backendError." + configurations.data.LoginMode.toLowerCase()
      );
   }

   // Force to use a general error code for 5xx errors
   if (httpResponse.status.toString().startsWith("5")) {
      message = translationContext.localizeValue("HttpHandlersSIG.backendError.500");
   }

   // Force to use a general error code for 404 errors
   if (httpResponse.status == "404") {
      message = translationContext.localizeValue("HttpHandlersSIG.backendError.404");
   }

   // No message found at all, use a general error message
   if (!message) {
      message = translationContext.localizeValue("HttpHandlersSIG.backendError.generalError");
   }

   if (message.includes("HttpHandlersSIG")) {
      actions.trace(TRACE_LEVEL.error, "Translation not found: " + message);
      message = configurations.data.General.BackendGeneralErrorText;
   }

   // Will not logout on errors based on validations resulting of user inputs and whom can be corrected by the user
   // Currently, only errors 400 and 409 are handled without logging out the user
   if (httpResponse.status == "400" || httpResponse.status == "409") {
      errorMessageWillLogOut = false;
   }

   // Set the stackTrace
   const { url, body, bodyUsed, headers, ok, redirected, status, statusText, type } = jsonResponseBody;
   const stackTrace = JSON.stringify({
      jsonResponseBody: { url, body, bodyUsed, headers, ok, redirected, status, statusText, type },
      fetchOptions,
   });

   // call post trace
   actions.trace(TRACE_LEVEL.error, `HTTP Status: ${httpResponse.status} - Message: ${message}`, stackTrace);

   actions.setErrorMessage({ errorMessage: message, errorMessageWillLogOut: errorMessageWillLogOut });

   // Returns a rejected promise for the raised an error
   // or a resolved promise if the user should stay logged in
   return errorMessageWillLogOut ? Promise.reject() : Promise.resolve();
}

/**
 * Makes the HTTP GET call to the received url with the access token and
 * rejects the Promise if it takes longer than the web services delay timeout to receive the
 * response.
 * @param {string} url - The url to call (can be an absolute url, or a relative url that will be 
 *                       concatenated with the web services url).
 * @param {Object} params - The parameters used to make the call. Will be parsed to query string.
 * @param {Object} options - The options passed to the http request.
 * @param {Object} translationContext - TranslationContext to localize text.
 * @param {Object} storeContext - The actions, authentification (if an access token must be added to the request)
 *                                and configurations from the store.
 * @param {boolean} excludeAccessToken - Indicates if the access token should be excluded from the request.
 */
export function httpGetWithAccessToken(
   url,
   params,
   options,
   translationContext,
   { actions, authentication, configurations, signConfig },
   excludeAccessToken
) {
   if (!params) {
      params = {};
   }

   const webServicesTimeout = configurations.data.General.WebServicesTimeout;
   const fetchOptions = excludeAccessToken ? options : addAccessToken(options, { authentication, configurations });

   // Start SITE-SPEC GVB2020 - Toujours mettre le Accept-Language dans le header de la requete
   if (!options.headers) {
      options.headers = {};
   }
   options.headers["Accept-Language"] = configurations.data.General.DefaultCulture;
   // End SITE-SPEC

   const errorHandler = errorHandlerCallback(translationContext, actions, configurations);

   const { webServicesUrl } = signConfig.data;
   const endPointUrl = url.startsWith("http") ? url : `${webServicesUrl}${url}`;

   const httpTimeoutHandler = () =>
      handleHttpTimeout(
         "Timeout while calling promise.",
         { url: endPointUrl, params, fetchOptions },
         translationContext,
         actions
      );

   // Handle web services request timeout.
   return promiseTimeout(
      webServicesTimeout,
      httpGet(endPointUrl, { params, fetchOptions, errorHandler }),
      httpTimeoutHandler
   ).catch((error) => {
      // If the web services request takes longer than the specified timeout the promise will be rejected.
      handleFetchException(error, endPointUrl, actions, configurations);
   });
}
/**
 * Makes the HTTP POST call to the received url with the access token
 * and rejects the Promise if it takes longer than the web services delay timeout to receive the
 * response.
 * @param {string} url - The url to call.
 * @param {Object} body - The body of the request.
 * @param {Object} options - The options passed to the http request.
 * @param {Object} translationContext - TranslationContext to localize text.
 */
export function httpPostWithAccessToken(
   url,
   body,
   options,
   translationContext,
   { actions, authentication, configurations, signConfig }
) {
   if (!body) {
      body = {};
   }

   const webServicesTimeout = configurations.data.General.WebServicesTimeout;
   const fetchOptions = addAccessToken(options, { authentication, configurations });

   const errorHandler = errorHandlerCallback(translationContext, actions, configurations);

   const { webServicesUrl } = signConfig.data;
   const endPointUrl = `${webServicesUrl}${url}`;

   const httpTimeoutHandler = () =>
      handleHttpTimeout(
         "Timeout while calling promise.",
         { url: endPointUrl, body, fetchOptions },
         translationContext,
         actions
      );

   // Handle web services request timeout.
   return promiseTimeout(
      webServicesTimeout,
      httpPost(endPointUrl, { body, fetchOptions, errorHandler }),
      httpTimeoutHandler
   ).catch((error) => {
      // If the web services request takes longer than the specified timeout the promise will be rejected.
      handleFetchException(error, endPointUrl, actions, configurations);
   });
}

/**
 * Handles errors that occur during an HTTP request and invokes the appropriate error handler function.
 *
 * @param {Object} translationContext - The translation context.
 * @param {Object} actions - The actions object.
 * @param {Object} configurations - The configurations object.
 * @returns {Function} - The callback function to handle HTTP errors.
 */
function errorHandlerCallback(translationContext, actions, configurations) {
   return function (httpResponse, jsonResponseBody, fetchOptions) {
      httpErrorHandler(httpResponse, jsonResponseBody, fetchOptions, translationContext, {
         actions,
         configurations,
      });
   };
}
