import React from "react";
import PropTypes from "prop-types";
import ClassNames from "classnames";

import DropDown from "giro-react-toolkit/source/DropDown";

import "./OvertimeCodeList.scss";

/**
 * DropDown list of overtime codes
 */
const OvertimeCodeList = props => {
   const overtimeCodeClasses = ClassNames("overtime-code-list", props.className);

   /**
    * Displays the list of overtime codes as drop down menu
    * The identifier of each code/menu item is its identifier from the list of overtime codes
    * The label of each code/menu item is a string created from its identifier and description
    * @param {array} - list of overtime codes to display
    * @returns {object} - Drop down of the list
    */
   function displayOvertimeCodes(list) {
      let selectedOvertimeCodeIdentifier;
      const elements = list.map(item => {
         if (item.Identifier == props.selectedOvertimeCodeIdentifier) {
            selectedOvertimeCodeIdentifier = item.Identifier;
         }
         return {
            label: item.Description,
            identifier: item.Identifier
         };
      });

      const generateChildren = (data, callback) => {
         return <div onClick={ callback }>{ data.label }</div>;
      };

      /**
       * Handles the change event of the dropdown element.
       * @param {object} item - selected item identifier.
       */
      function onDropDownChange(item) {
         let selectedOvertimeCodeIdentifier = item.identifier;
         if (props.onChange) {
            props.onChange(selectedOvertimeCodeIdentifier);
         }
      }

      return (
         <DropDown
            items={ elements }
            value={ selectedOvertimeCodeIdentifier }
            valueAttribute={ "identifier" }
            renderItem={ generateChildren }
            bubbleClassName={ "overtime-code-bubble" }
            onChange={ onDropDownChange }
         />
      );
   }

   return <div className={ overtimeCodeClasses }>{ displayOvertimeCodes(props.overtimeCodeList) }</div>;
};

OvertimeCodeList.propTypes = {
   /** CSS classnames added to the component */
   className: PropTypes.string,
   /** List of overtime code */
   overtimeCodeList: PropTypes.arrayOf(PropTypes.object).isRequired,
   /** Function called when selected value changed */
   onChange: PropTypes.func,
   /** Selected overtime code identifier */
   selectedOvertimeCodeIdentifierIdentifier: PropTypes.string
};

export default OvertimeCodeList;
