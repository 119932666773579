import { initialState } from "../initialState.js";
import { TimeConverter } from "giro-react-toolkit";
import { Context, httpGetWithAccessToken, parseClockMode, TRACE_LEVEL } from "UtilityFunctions";

/**
 * Application trace tag.
 */
const APPLICATION_TRACE_TAG = "SIGN-KIOSK";

/**
 * Actions relative to the configurations.
 */
const ConfigurationsServiceActions = {
   getSignConfigurations: async (_, actions) => {
      const signConfigurationsResponse = await fetch("sign.config.json");
      const signConfigurations = await signConfigurationsResponse.json();

      // Accessing URL parameters to check if 'kiosk-id' is provided.
      const urlParams = new URLSearchParams(window.location.search);
      let kioskId = urlParams.get("kiosk-id");

      // Check if 'kiosk-id' parameter is missing.
      if (!kioskId) {
         // Check for a value in local storage.
         kioskId = localStorage.getItem("sign-kiosk-id");

         if (!kioskId) {
            actions.trace(TRACE_LEVEL.warn, "The kiosk-id is not provided, default configurations will be used.");
            // Set kioskId as default to avoid tracing more than once.
            kioskId = "default";
         }
      } else if (!(kioskId in signConfigurations)) {
         // En cas d'un id invalide : retomber sur default, 
         // mais conserver ce qui a été spécifié dans l'url pour les traces. 
         // Le but de ceci est de permettre de bien différencier les kiosques sans
         // qu'il ne soit obligatoire de les spécifier dans le sign.json.config.
         actions.trace(TRACE_LEVEL.warn, `Invalid kiosk-id ${ kioskId }, default configurations will be used.`);
      }

      // Keep kiosk id in local storage for pages that don't have the kiosk-id parameter.
      localStorage.setItem("sign-kiosk-id", kioskId);

      // Extract the 'WebServicesUrl' and 'IniContext' values based on the determined 'kioskId'.
      const configurationsForKioskId = kioskId in signConfigurations ?
         signConfigurations[kioskId] : signConfigurations["default"];

      // If 'WebServicesUrl' is not provided for the determined 'kioskId', fallback to the default 'WebServicesUrl'.
      // If the default 'WebServicesUrl' is empty use the current url.
      const webServicesUrl = (configurationsForKioskId.WebServicesUrl || signConfigurations.default.WebServicesUrl ||
         window.location.pathname).replace(/\/$/, "");

      const iniContext = configurationsForKioskId.IniContext || signConfigurations.default.IniContext;

      return ({
         signConfig: {
            data: { webServicesUrl, iniContext, kioskId },
            traceTags: [ APPLICATION_TRACE_TAG, kioskId ],
            isLoaded: true,
         },
      });
   },

   /**
    * Gets SignIni and update the configurations
    */
   getWebServicesConfigurations: async ({ configurations, signConfig }, actions, translationContext) => {
      // fetch the configurations "signIni"
      const signIni = await httpGetWithAccessToken(
         "/OperationGeneralServiceRest/SignConfiguration",
         { IniContext: signConfig.data.iniContext ? signConfig.data.iniContext.join() : "" },
         {},
         translationContext,
         { actions, configurations, signConfig },
         true /* exclude access token. */
      );

      const { General } = configurations.data;

      // Return an object with the combined configurations
      return {
         configurations: {
            ...configurations,
            data: {
               ...signIni, // Spread the properties from signIni object
               General, // Include the General property from configurations.data
            },
         },
      };
   },

   /**
    * Gets and sets the application configurations.
    */
   getConfigurations: async ({ application, authentication, configurations, signConfig },
      actions,
      translationContext) => {
      if (configurations.isLoaded) {
         return;
      }

      // Fetch the local installation corporate messages file.
      const corporateMessagesResponse = await fetch("corporateMessages.json");
      const defaultCorporateMessages = await corporateMessagesResponse.json();

      // Get configurations from the Kiosk.
      let configurationsToUse = configurations.data;
      let corporateMessages =
         defaultCorporateMessages[configurationsToUse.General.DefaultCulture] ||
         initialState.application.corporateMessages;

      // If loaded from CefSharp, get configurations from there.
      if (Context.isWebOnly() === false) {
         // Aller chercher l'objet WebBrowserHostScriptingObject que
         // CefSharp va mettre dans une variable globale webBrowserHostScriptingObject.
         await CefSharp.BindObjectAsync("webBrowserHostScriptingObject");
         const applicationConfigurations = await webBrowserHostScriptingObject.getApplicationConfiguration();
         configurationsToUse = JSON.parse(applicationConfigurations);

         // Fetch the local installation corporate messages file.
         const externalCorporateMessages = await httpGetWithAccessToken(
            "externals/corporateMessages.json",
            null,
            { signal: application.abortController.signal },
            translationContext,
            { actions, authentication, configurations, signConfig },
            true /* exclude access token. */
         );

         if (externalCorporateMessages && externalCorporateMessages[configurationsToUse.General.DefaultCulture]) {
            corporateMessages = externalCorporateMessages[configurationsToUse.General.DefaultCulture];
         } else {
            actions.trace(
               TRACE_LEVEL.info,
               `No corporate messages found using culture "${configurationsToUse.General.DefaultCulture}".`
            );
            corporateMessages = [];
         }

         webBrowserHostScriptingObject.onInitializationCompleted();
      }
      // Set TimeConverter clock mode.
      const supportedCulturesClockModes = parseClockMode(configurationsToUse.ClockMode);
      const currentCultureClockMode = supportedCulturesClockModes[configurationsToUse.General.DefaultCulture];
      TimeConverter.clockMode = currentCultureClockMode || supportedCulturesClockModes["*"] || "Mode24";

      return {
         application: {
            ...application,
            corporateMessages: corporateMessages,
         },
         configurations: {
            ...configurations,
            data: configurationsToUse,
            isLoaded: true,
         },
      };
   },
};

export default ConfigurationsServiceActions;
