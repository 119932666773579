import React, { Component } from "react";

import { Button, Icon, Tile, TimeConverter, Translation, TranslationContext } from "giro-react-toolkit";
import { KioskHeader } from "giro-kiosk-base";
import { actions, connect } from "store";

import "./ChoicePage.scss";

import signInIcon from "images/login.svg";
import signOutIcon from "images/logout.svg";

/**
 * Choice page where the employee can choose to sign in or sign out.
 * After choosing, they are redirected to the message page if they have messages that
 * must be force acknowledged or they go directly to the sign in/out page.
 */
class Choice extends Component {
   constructor(props) {
      super(props);

      this.onSignClick = this.onSignClick.bind(this);
   }

   /**
    * Handles the choice of sign. Will redirect to the EmployeeMessages
    * page if there are messages that are forced to be acknowledged.
    *
    * @param {('signin'|'signout')} signChoice - Choice of the sign.
    */
   onSignClick(signChoice) {
      const mustAcknowledgeMessages = this.props.employeeMessages.some(
         x => x.ForceToAcknowledgeForSignInOut === true && !x.MessageHasBeenAcknowledged);

      if (mustAcknowledgeMessages) {
         this.props.history.push({
            pathname: "/employeemessages",
            state: { signChoice }
         });
      } else if (signChoice) {
         this.props.history.push(signChoice);
      } else {
         this.props.history.push(signChoice);
      }
   }

   render() {
      return (
         <div className="choice__container">
            <KioskHeader
               userName={ this.props.employeeInfo.DisplayName }
               userPicture={ this.props.employeeInfo.Photo }
               userMsg={ this.context.localizeValue("GlobalSIG.header.signedAtText", [
                  TimeConverter.formatISODateStringToShortTimeString(this.props.loginTime, this.context.localizeValue)
               ]) }
               className="choice__kiosk-header"
            />

            <div className="choice__tiles">
               <Tile
                  scale="large"
                  accent="secondary"
                  className="choice__tile"
                  onClick={ () => this.onSignClick("signin") }
               >
                  <Icon path={ signInIcon } scale="large" color="color-primary" className="choice__icon" />
                  <Translation resourceKey="ChoicePageSIG.signInButtonLabel" />
               </Tile>
               <Tile
                  scale="large"
                  accent="secondary"
                  className="choice__tile"
                  onClick={ () => this.onSignClick("signout") }
               >
                  <Icon path={ signOutIcon } scale="large" color="color-primary" className="choice__icon" />
                  <Translation resourceKey="ChoicePageSIG.signOutButtonLabel" />
               </Tile>
            </div>
            <Button accent="secondary" scale="extra-large" onClick={ () => actions.logOut() }>
               <Translation resourceKey="GlobalSIG.cancelButtonLabel" />
            </Button>
         </div>
      );
   }
}

Choice.contextType = TranslationContext;

export default connect(state => ({
   employeeInfo: state.employeeInfo,
   employeeMessages: state.employeeMessages,
   loginTime: state.authentication.loginTime
}))(Choice);
