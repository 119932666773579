import { httpPostWithAccessToken, httpGetWithAccessToken } from "UtilityFunctions";

/**
 * Actions relative to the connected employee.
 */
const EmployeeServiceActions = {
   /**
    * Gets the information indicating if service delays can be managed by the current employee.
    * Automatically called by logIn.
    */
   getCanEmployeeManageServiceDelays: async (
      { application, authentication, configurations, employeeInfo, signConfig },
      actions,
      translationContext
   ) => {
      const data = await httpGetWithAccessToken(
         "/OperationDailyServiceRest/CanManageServiceDelays/EmployeeIdentifier/" + employeeInfo.Identifier +
         "/Date/" + authentication.loginTime,
         null,
         { signal: application.abortController.signal },
         translationContext,
         { actions, authentication, configurations, signConfig }
      );

      return { canEmployeeManageServiceDelays: data.CanManageServiceDelays };
   },

   /**
    * Gets the employee information using the token and the username.
    * Automatically called by logIn.
    */
   getEmployeeInfo: async (
      { application, authentication, configurations, signConfig },
      actions,
      { fromCard, translationContext, username }
   ) => {
      const employeeInfo = await httpPostWithAccessToken(
         "/OperationEmployeeServiceRest/EmployeeIdentification",
         {
            EmployeeIdentifier: username,
            IsEncrypted: fromCard,
         },
         { signal: application.abortController.signal },
         translationContext,
         { actions, authentication, configurations, signConfig }
      );

      return { employeeInfo };
   },

   /**
    * Gets the messages for the connected employee.
    * Automatically called by logIn.
    */
   getEmployeeMessages: async (
      { application, authentication, configurations, employeeInfo, signConfig },
      actions,
      translationContext
   ) => {
      const {
         EmployeeMessages: employeeMessages,
      } = await httpGetWithAccessToken(
         "/OperationEmployeeServiceRest/EmployeeMessages/" + employeeInfo.Identifier,
         null,
         { signal: application.abortController.signal },
         translationContext,
         { actions, authentication, configurations, signConfig }
      );

      return { employeeMessages };
   },

   /**
    * Gets the EmployeeInfo, EmployeeMessages and CanEmployeeManageServiceDelays.
    * Called by logIn.
    */
   getEmployeeRelatedInfo: async (state, actions, { fromCard, translationContext, username }) => {
      await actions.getEmployeeInfo({ fromCard, translationContext, username });
      // Employee messages require the employee information
      await actions.getEmployeeMessages(translationContext);
      await actions.getCanEmployeeManageServiceDelays(translationContext);

      return;
   },
};

export default EmployeeServiceActions;
