/* eslint-disable no-console */

import React, { Component, Fragment } from "react";
import ClassNames from "classnames";

import { Button, Icon, Loading, TimeConverter, Translation, TranslationContext } from "giro-react-toolkit";

import MessagePanel from "Components/MessagePanel";
import Pieces from "Components/Pieces";
import { actions, connect } from "store";
import { Context, httpPostWithAccessToken } from "UtilityFunctions";

import "./SignInPage.scss";
import printIcon from "images/print.svg";
import checkedIcon from "images/checkbox-checked-icon.svg";
import cardIcon from "images/simple-card.svg";
import blackCircleIcon from "images/black-circle-icon.svg";

/**
 * Interactive sign in page
 */
class SignInPage extends Component {
   constructor(props) {
      super(props);
      this.state = {
         // Determines if the fullscreen loading is displayed.
         displayLoading: true,
         // Indicates if the print DTC button is disabled. It is disabled while printing to avoid
         // pressing it more than once.
         disablePrintDTCButton: false,
         // Indicates if the message from the print DTC is an error (true if it came from the kiosk).
         isPrintDTCMessageError: false,
         // Message to display on top of the page to indicate that the DTC was printed, or in error.
         printDTCMessage: "",
         // List of service messages (stays null if service messages don't exist)
         serviceMessages: null,
         // Sign in information. null when the sign in is not yet complete.
         signInInfo: null,
         // Sign in time.
         signInTime: null,
      };
      this.afterPrintDTCCallback = this.afterPrintDTCCallback.bind(this);
      this.onPrintDTCReport = this.onPrintDTCReport.bind(this);
   }

   /**
    * Called after printing the DTC.
    * @param {string} employeeID - employee id for which the error message was returned.
    * @param {string} errorMessage - error message received from the print request, if any.
    */
   afterPrintDTCCallback(employeeID, errorMessage) {
      // If another employe, don't consider the callback since it's a print DTC that was launched
      // earlier by another employee.
      if (employeeID !== this.props.employeeId) {
         return;
      }

      if (errorMessage) {
         this.setState({ printDTCMessage: errorMessage, disablePrintDTCButton: false, isPrintDTCMessageError: true });
      } else {
         // Message is set by a callback and not during render, so standard transaction tools cannot
         // be used. With React 16.6, we should be able to use something more standard like:
         // this.context.localizeValue({key:'SignInPageSIG.dtcPrinted'})
         this.setState({
            printDTCMessage: <Translation resourceKey={ "SignInPageSIG.dtcPrinted" } />,
            disablePrintDTCButton: false,
            isPrintDTCMessageError: false,
         });
      }
   }

   /**
    * Functions that launches the print DTC.
    * Le service web supporte plusieurs rapports, mais on en a toujours géré un seul. On
    * pourrait appeler l'application kiosque plusieurs fois si on veut en gérer plusieurs,
    * ou passer plusieurs PDF.
    */
   onPrintDTCReport() {
      // Make sure that the message is emptied and the button disabled before printing and then receiving an answer
      this.setState({ printDTCMessage: "", disablePrintDTCButton: true, isPrintDTCMessageError: false }, async () => {
         const result = await httpPostWithAccessToken(
            "/OperationDTCServiceRest/GenerateDriverTimeCardPDF",
            {
               DTCName: "DTC1",
               EmployeeIdentifier: this.props.employeeId,
               Date: this.props.loginTime,
               DivisionIdentifier: "",
               HorizontalMargins: this.props.DTCHorizontalMargins,
               VerticalMargins: this.props.DTCVerticalMargins,
            },
            { signal: this.props.abortControllerSignal },
            this.context,
            { 
               actions, 
               authentication: this.props.stateAuthentication, 
               configurations: this.props.stateConfigurations,
               signConfig: this.props.signConfig
            }
         );

         const errorMessage = await webBrowserHostScriptingObject.hostPrintPDFFile(result[0].PDFReportString);

         this.afterPrintDTCCallback(this.props.employeeId, errorMessage);
      });
   }

   componentDidMount() {
      const signInAndGetInformationPromise = () => {
         return httpPostWithAccessToken(
            "/OperationEmployeeServiceRest/SignInAndGetInformation",
            {
               EmployeeIdentifier: this.props.employeeId,
               SignDateTime: this.props.loginTime,
               SignSiteIdentifier: this.props.signInSiteIdentifier,
               SourceOfSign: "Sign",
            },
            { signal: this.props.abortControllerSignal },
            this.context,
            { 
               actions, authentication: this.props.stateAuthentication, 
               configurations: this.props.stateConfigurations,
               signConfig: this.props.signConfig
            }
         );
      };

      signInAndGetInformationPromise().then((json) => {
         const receiptContent = json.EmployeeReceiptText + json.ReceiptContentText;

         if (
            json.SignInInvalidCode &&
            json.SignInInvalidCode !== "AlreadySignedIn" &&
            json.SignInInvalidCode !== "SignInInfo"
         ) {
            !Context.isWebOnly() && webBrowserHostScriptingObject.printInvalidSignInText(receiptContent);

            actions.setErrorMessage({ errorMessage: json.SignInInvalidMessage });
         } else {
            !Context.isWebOnly() && webBrowserHostScriptingObject.printValidSignInText(receiptContent);

            this.setState({
               signInInfo: json,
               serviceMessages:
                  json.SignInInformation && json.SignInInformation.ServiceMessages
                     ? json.SignInInformation.ServiceMessages
                     : [],
               displayLoading: false,
               signInTime: json.LastSignInTime ? json.LastSignInTime : this.props.loginTime,
            });

            if (json.SignInInvalidCode === "AlreadySignedIn" || json.SignInInvalidCode === "SignInInfo") {
               actions.setHeaderMessage(json.SignInInvalidMessage);
            }
         }
      });
   }

   render() {
      const signInPrintMessageClassName = ClassNames("sign-in__print-message", {
         "sign-in__print-message--error": this.state.isPrintDTCMessageError,
      });

      return (
         <div className="sign-in__content">
            <Loading isFullScreen isDisplayed={ this.state.displayLoading }>
               <div className="sign-in__scroll sign-in__scroll-pieces">
                  <div className="sign-in__piece-section">
                     <div className="sign-in__title-section">
                        <div className="sign-in__title-section-left">
                           <Icon path={ checkedIcon } color="color-primary" className="sign-in__title-section-icon" />
                           <span>
                              { this.context.localizeValue("SignInPageSIG.signInTimeTitle", [
                                 /*time:*/ TimeConverter.formatISODateStringToShortTimeString(
                                    this.state.signInTime,
                                    this.context.localizeValue
                                 ),
                              ]) }
                           </span>
                        </div>
                        { this.props.displayPrintDTCButton && (
                           <Fragment>
                              <div className={ signInPrintMessageClassName }>{ this.state.printDTCMessage }</div>
                              <Button
                                 accent="secondary"
                                 className="sign-in__print-button"
                                 disabled={ this.state.disablePrintDTCButton }
                                 onClick={ this.onPrintDTCReport }
                              >
                                 <Icon path={ printIcon } color="color-primary" className="sign-in__print-icon" />
                                 <Translation resourceKey="SignInPageSIG.printButtonLabel" />
                              </Button>
                           </Fragment>
                        ) }
                     </div>

                     <div className="sign-in__grey-line">
                        <div className="sign-in__pieces">
                           <Pieces
                              displayContext="SignIn"
                              listData={
                                 /*time:*/ this.state.signInInfo && this.state.signInInfo.SignInInformation
                                    ? this.state.signInInfo.SignInInformation.RemainingWorkPieces
                                    : []
                              }
                           />
                        </div>
                     </div>

                     <div className="sign-in__title-section">
                        { this.state.signInInfo && this.state.signInInfo.NextExpectedSignInDateTime ? (
                           <div className="sign-in__title-section-left sign-in__title-section-later">
                              <Icon path={ cardIcon } color="grey-dark" className="sign-in__title-section-icon" />
                              <span>
                                 { this.context.localizeValue("SignInPageSIG.nextSignInTimeTitle", [
                                    TimeConverter.formatISODateStringToShortTimeString(
                                       this.state.signInInfo.NextExpectedSignInDateTime,
                                       this.context.localizeValue
                                    ),
                                 ]) }
                              </span>
                           </div>
                        ) : (
                           <div className="sign-in__title-section-left">
                              <Icon
                                 path={ blackCircleIcon }
                                 color="black"
                                 className="sign-in__title-section-icon--circle"
                              />
                              <span>{ this.context.localizeValue("SignInPageSIG.endOfDutyText") }</span>
                           </div>
                        ) }
                     </div>
                  </div>
                  <MessagePanel serviceMessages={ this.state.serviceMessages } />
               </div>
            </Loading>
         </div>
      );
   }
}

SignInPage.contextType = TranslationContext;

export default connect((state) => ({
   abortControllerSignal: state.application.abortController.signal,
   displayPrintDTCButton: state.configurations.data.General.DisplayPrintDTCButton,
   DTCHorizontalMargins: state.configurations.data.SignInDTCHorizontalMargins,
   DTCVerticalMargins: state.configurations.data.SignInDTCVerticalMargins,
   employeeId: state.employeeInfo.Identifier,
   isDailyVehicleAvailable: state.configurations.isDailyVehicleAvailable,
   isDelayCreationEnabled: state.configurations.data.SignOutEnableDelayCreation,
   loginTime: state.authentication.loginTime,
   signInSiteIdentifier: state.configurations.data.SignInSiteIdentifier,
   stateConfigurations: state.configurations,
   stateAuthentication: state.authentication,
   signConfig: state.signConfig,
}))(SignInPage);