import { httpGetWithAccessToken } from "UtilityFunctions";

/**
 * Actions relative to daily operations.
 */
const DailyServiceActions = {
   getDailyVehicleModuleAvailability: async (
      { application, authentication, configurations, signConfig },
      actions,
      translationContext
   ) => {
      if (configurations.isDailyVehicleAvailable !== null) {
         return;
      }

      const data = await httpGetWithAccessToken(
         "/OperationDailyServiceRest/IsDailyVehicleAvailable",
         null,
         { signal: application.abortController.signal },
         translationContext,
         { actions, authentication, configurations, signConfig }
      );

      return {
         configurations: {
            ...configurations,
            isDailyVehicleAvailable: data.IsAvailable
         }
      };
   },

  /**
   * Get delay reasons only if SIGN_OUT_ENABLE_DELAY_CREATION is true.
   */
   getDelayReasons: async (
      { application, authentication, configurations, delayReasons, signConfig },
      actions,
      translationContext
   ) => {
      if (!configurations.data.SignOutEnableDelayCreation || delayReasons.isLoaded) {
         return;
      }

      const data = await httpGetWithAccessToken(
         "/OperationDailyServiceRest/ServiceDelayReasonsConfiguration",
         null,
         { signal: application.abortController.signal },
         translationContext,
         { actions, authentication, configurations, signConfig }
      );

      return {
         delayReasons: {
            data: data[0].ServiceDelayReasons,
            isLoaded: true
         }
      };
   },

  /**
   * Get delay overtime codes only if SIGN_OUT_ENABLE_DELAY_CREATION is true.
   */
   getDelayOvertimeCodes: async (
      { application, authentication, configurations, delayOvertimeCodes, signConfig },
      actions,
      translationContext
   ) => {
      if (!configurations.data.SignOutEnableDelayCreation || delayOvertimeCodes.isLoaded) {
         return;
      }

      const data = await httpGetWithAccessToken(
         "/OperationDailyServiceRest/ServiceDelayOvertimeCodes",
         null,
         { signal: application.abortController.signal },
         translationContext,
         { actions, authentication, configurations, signConfig }
      );

      return {
         delayOvertimeCodes: {
            data: data,
            isLoaded: true
         }
      };
   },

   /**
    * This is only done once after the first user logs in.
    * Gets the following information:
    * - DailyVehicleModuleAvailability
    * - DelayReasons
    * - DelayOvertimeCodes
    */
   getGeneralConfigurations: async (state, actions, translationContext) =>
      Promise.all([
         actions.getDailyVehicleModuleAvailability(translationContext),
         actions.getDelayReasons(translationContext),
         actions.getDelayOvertimeCodes(translationContext)
      ])
};

export default DailyServiceActions;
