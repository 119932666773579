import ApplicationServiceActions from "./applicationService";
import AuthenticationWebServiceActions from "./authenticationWebService";
import ConfigurationsServiceActions from "./configurationsService";
import DailyWebServiceActions from "./dailyWebService";
import EmployeeWebServiceActions from "./employeeWebService";
import RepositoryWebServiceActions from "./repositoryWebService";

export default Object.assign(
   ApplicationServiceActions,
   AuthenticationWebServiceActions,
   ConfigurationsServiceActions,
   DailyWebServiceActions,
   EmployeeWebServiceActions,
   RepositoryWebServiceActions
);
