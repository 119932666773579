import defaultConfigurations from "App/configurations.json";

/**
 * Initial state of the data store.
 *
 * - It is preferable to not nest objects in order to avoid unnecessary renders
 *   and for easier state assignments (too many spread operators).
 * - If an object is complex and a component is connected to one of its values,
 *   that object must be correctly initialized:
 * @example
 * // Connect.
 * export default connect(state => ({
 *    signMode: state.configurations.data.General.SignMode
 * }))(App);
 *
 * // Incorrect.
 * const initialState: {
 *    configurations: null // Will crash above.
 * }
 *
 * // Correct.
 * const initialState: {
 *    configurations: { data: { General: {} } }
 * }
 */
export const initialState = {
   /** Application global states and objects. */
   application: {
      /** AbortController used to cancel fetch requests on log out. */
      abortController: new AbortController(),
      /** Messages to show in the footer. */
      corporateMessages: [],
      /** Global error message to show. */
      errorMessage: null,
      errorMessageWillLogOut: true,
      /** Message shown in the header. */
      headerMessage: null,
      /** Used to force an instance change on the app's KioskFooter to allow resetting the timeout.*/
      footerKeyHash: 0,
   },
   controllers: [],
   /** Token information of the connected user. */
   authentication: {
      /**
       * Used in the HomePage component when logging in.
       * Set to false when we have the token AND the employeeInfo.
       */
      isAuthenticating: false,
      isLoggedIn: false,
      /** Used when Signing in and out. */
      loginTime: null,
      token: {},
   },
   /** Information indicating if service delays can be managed by the current employee. */
   canEmployeeManageServiceDelays: null,
   /** Employee information relative to the connected user. */
   employeeInfo: {},
   /** Employee messages. */
   employeeMessages: [],

   /** Application configurations. Loaded once per initialization. */
   configurations: {
      data: defaultConfigurations,
      isLoaded: false,
      isDailyVehicleAvailable: null,
   },
   /** Delay reasons */
   delayReasons: {
      data: [],
      isLoaded: false,
   },
   /** Delay overtime codes */
   delayOvertimeCodes: {
      data: [],
      isLoaded: false,
   },
   /** Fieldsets */
   fieldsets: {
      data: {
         SignInRow1: {},
         SignInRow2: {},
         SignOutRow1: {},
         SignOutRow2: {},
         SignOutDelayRow1: {},
         SignOutDelayRow2: {},
      },
      isLoaded: false,
   },
   translations: {
      data: {},
      isLoaded: false,
   },
   /** Ini context and web services url for current kiosk-id in sign.json.config. */
   signConfig: {
      data: { },
      isLoaded: false,
   },
};
