import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ClassNames from "classnames";

import EmployeeMessages from "Components/EmployeeMessages";
import ServiceMessages from "Components/ServiceMessages";
import { connect } from "store";

import "./MessagePanel.scss";

/**
 * Displays employee messages and service messages in a panel.
 */
class MessagePanel extends PureComponent {
   constructor(props) {
      super(props);
   }

   render() {
      const className = ClassNames(
         "message-panel",
         { "message-panel--with-service-messages": this.props.isDailyVehicleAvailable },
         this.props.className
      );

      return (
         <div className={ className }>
            { this.props.isDailyVehicleAvailable && <ServiceMessages listData={ this.props.serviceMessages } /> }
            <EmployeeMessages />
         </div>
      );
   }
}

MessagePanel.propTypes = {
   /** CSS class names added to the component. */
   className: PropTypes.string,
   /** Service messages to display. */
   serviceMessages: PropTypes.arrayOf(PropTypes.object)
};

MessagePanel.defaultProps = {
   serviceMessages: []
};

export default connect(state => ({
   isDailyVehicleAvailable: state.configurations.isDailyVehicleAvailable
}))(MessagePanel);
