/**
 * Parse the clock mode from the input string.
 * @param {string} input - The input string containing the clock mode information, e.g., "en=0;*=1"
 * @returns {Object} The parsed clock mode represented as an object, e.g., { "en": "Mode12", "*": "Mode24" }
 */
export const parseClockMode = (input = "") => {
   const modes = ["Mode12", "Mode24", "Mode24WithDayDesignator"];
   const obj = {};
   input.split(";").forEach((pairs) => {
      let [key, value] = pairs.split("=");
      obj[key] = modes[value];
   });
   return obj;
};
