import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route } from "react-router-dom";

import App from "./App";
import SignErrorBoundary from "Components/SignErrorBoundary";
import { Provider as StoreProvider } from "./store";
import { TranslationProvider } from "giro-react-toolkit";

ReactDOM.render(
   <StrictMode>
      <StoreProvider>
         <TranslationProvider defaultCulture={ "" } translations={ {} } toolkitTranslationKey="ToolkitSIG">
            <BrowserRouter basename={ window.location.pathname }>
               <SignErrorBoundary>
                  <Route path="/" component={ App } />
               </SignErrorBoundary>
            </BrowserRouter>
         </TranslationProvider>
      </StoreProvider>
   </StrictMode>,
   document.getElementById("root")
);
