import { httpPost } from "giro-react-toolkit";
import { SESSION_ID } from "UtilityFunctions/Constants";

const APPLICATION_TRACE_MODULE = "SIGN";

/**
 * Actions relative to the application.
 */
const ApplicationServiceActions = {
   /**
    * Clears the error message to show in the application.
    */
   clearErrorMessage: ({ application }) => ({
      application: {
         ...application,
         errorMessage: null,
         errorMessageWillLogOut: true,
      },
   }),

   /**
    * Sets the error message to show in the application.
    * This message will appear fullscreen and can log out the user when closed.
    *
    * @param {string} errorMessage - The error message to show.
    * @param {boolean} errorMessageWillLogOut - Whether to log out the user when closed.
    */
   setErrorMessage: ({ application }, actions, { errorMessage, errorMessageWillLogOut = true }) => ({
      application: {
         ...application,
         errorMessage,
         errorMessageWillLogOut,
      },
   }),

   /**
    * Sets the header message.
    *
    * @param {string} headerMessage - Header message to set.
    */
   setHeaderMessage: ({ application }, actions, headerMessage) => ({
      application: {
         ...application,
         headerMessage,
      },
   }),

   /**
    * Resets the session timeout to its' full duration.
    * This is acheived by recreating a new value for the app's footer key which creates a new instance to hold the
    * timer.
    */
   resetSessionTimeout: ({ application }) => ({
      application: {
         ...application,
         footerKeyHash: Math.random(),
      },
   }),

   /**
    * Posts a trace message along with relevant information to a specific endpoint.
    * 
    * @param {object} traceLevel - The trace level indicating the severity of the message.
    * Should be one of the values defined in the constant TRACE_LEVEL
    * @param {string} message - The trace message to be logged.
    * @param {string} responseTrace - The response from a previous HTTP request (optional).
    */
   trace: async ({ configurations, signConfig }, actions, traceLevel, message, responseTrace) => {
      // console log the message
      console[traceLevel.tag](message);

      if (responseTrace) {
         // Vider la trace s'il y a un mot de passe.
         responseTrace = responseTrace.includes("password=") ? null : responseTrace;
      }

      if (responseTrace) {
         console.info(JSON.parse(responseTrace));
      }
      
      if (!signConfig.isLoaded ||
         !configurations.isLoaded ||
         traceLevel.value > configurations.data.TraceLevel) {
         return;
      }

      // Create the body object for the HTTP POST request
      const body = {
         Module: APPLICATION_TRACE_MODULE,
         Level: traceLevel.value,
         Tags: signConfig.traceTags,
         Message: `${ traceLevel.tag }: ${ message }`,
         TracingData: {
            SessionId: SESSION_ID,
            StackTrace: responseTrace,
            KioskId: signConfig.data.kioskId,
         },
      };

      const errorHandler = (httpResponse, jsonResponseBody, fetchOptions) =>
         console.error({ httpResponse, jsonResponseBody, fetchOptions });

      httpPost(`${ signConfig.data.webServicesUrl }/OperationGeneralServiceRest/Trace`, 
         { body, fetchOptions: {}, errorHandler });

      return;
   },
};

export default ApplicationServiceActions;
