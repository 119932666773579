import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import ClassNames from "classnames";

import "./InputSpinner.scss";

/**
 * Number input spinner who's value ranges from a given minimum to maximum.
 * The user changes the input by clicking on the + and - buttons.
 * The value of the spinner can be passed to its parent via props.onChange.
 */
class InputSpinner extends PureComponent {
   constructor(props) {
      super(props);
      this.state = {
         value: null
      };
      this.increment = this.increment.bind(this);
      this.decrement = this.decrement.bind(this);
   }

   static getDerivedStateFromProps(nextProps) {
      if (nextProps.initialValue !== null) {
         return {
            value: nextProps.initialValue
         };
      }
      //if the initial value is not passed as props, the minimum value will be its initial value
      return {
         value: nextProps.min
      };
   }

   /**
    * Increments the spinner input's value.
    * It updates the state and the value passed to the parent.
    */
   increment() {
      this.setState((prevState, props) => {
         if (prevState.value === props.max) {
            props.onChange(props.min);
            return { value: props.min };
         }
         props.onChange(prevState.value + 1);
         return { value: prevState.value + 1 };
      });
   }

   /**
    * Decrements the spinner input's value.
    * It updates the state and the value passed to the parent.
    */
   decrement() {
      this.setState((prevState, props) => {
         if (prevState.value === props.min) {
            props.onChange(props.max);
            return { value: props.max };
         }
         props.onChange(prevState.value - 1);
         return { value: prevState.value - 1 };
      });
   }

   render() {
      const spinnerClasses = ClassNames("input-spinner", this.props.className);
      return (
         <div className={ spinnerClasses }>
            <div className="input-spinner__plus" onClick={ this.increment }>
               +
            </div>
            <div className="input-spinner__digit">{ this.state.value }</div>
            <div className="input-spinner__minus" onClick={ this.decrement }>
               -
            </div>
         </div>
      );
   }
}

InputSpinner.propTypes = {
   /** CSS classnames added to the component. */
   className: PropTypes.string,
   /** Function that updates the input spinner value for the parent. */
   onChange: PropTypes.func,
   /** Maximum value */
   min: PropTypes.number,
   /** Minimum value */
   max: PropTypes.number,
   /** Input spinner's initial value, if none, the min is the inital value */
   initialValue: PropTypes.number
};

InputSpinner.defaultProps = {
   min: 0,
   max: 9,
   initialValue: null
};

export default InputSpinner;
