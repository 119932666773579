import React from "react";
import PropTypes from "prop-types";
import ClassNames from "classnames";

import { RadioButton, RadioGroup } from "giro-react-toolkit";

import "./ReasonList.scss";

/**
 * RadioButton list of reasons
 */
const ReasonList = props => {
   const reasonClasses = ClassNames("reason-list", props.className);

   /**
    * Displays the list of reasons as radio buttons
    * the value of each reason/radiobutton is its identifier from the list of reasons
    * @param {array} - list of reasons to display
    * @returns {object} - RadioButton for each reason of the list
    */
   function displayReasons(list) {
      return list.map((item, index) => {
         return <RadioButton label={ item.Description } value={ item.Identifier } key={ index } />;
      });
   }

   /**
    * Handles the change event of the radio buttons.
    * @param {object} event - change event data.
    */
   function onRadioButtonChange(event) {
      let selectedReasonIdentifier = event.target.value;
      if (props.onChange) {
         props.onChange(selectedReasonIdentifier);
      }
   }

   return (
      <div className={ reasonClasses }>
         <RadioGroup
            className="reason-list__field"
            direction="column"
            onChange={ onRadioButtonChange }
            selectedValue={ props.selectedReasonIdentifier }
         >
            { displayReasons(props.reasonList) }
         </RadioGroup>
      </div>
   );
};

ReasonList.propTypes = {
   /** CSS classnames added to the component */
   className: PropTypes.string,
   /** List of reasons */
   reasonList: PropTypes.arrayOf(PropTypes.object).isRequired,
   /** Function called when selected value changed */
   onChange: PropTypes.func,
   /** Selected reason identifier */
   selectedReasonIdentifier: PropTypes.string
};

export default ReasonList;
