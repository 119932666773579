import React, { Component, Fragment } from "react";

import { Icon, Loading, TimeConverter, TranslationContext } from "giro-react-toolkit";
import Delay from "./Delay";
import EmployeeMessages from "Components/EmployeeMessages";
import Pieces from "Components/Pieces";
import { actions, connect } from "store";
import { httpPostWithAccessToken } from "UtilityFunctions";

import "./SignOutPage.scss";
import checkboxCircleWhiteIcon from "images/checkbox-circle-white-icon.svg";
import checkedIcon from "images/checkbox-checked-icon.svg";

class SignOutPage extends Component {
   constructor(props) {
      super(props);
      this.state = {
         // Piece that is currently selected for a delay.
         delayPiece: null,
         // Determines if the fullscreen loading is displayed.
         displayLoading: true,
         // Determines if the loading for pieces is displayed. Usually displayed after delays creation.
         displayPiecesLoading: false,
         // Callback called by Delay component to save a delay.
         handleCreateOrUpdateDelayWithAction: null,
         // Sign Out information (contains signed out pieces). null when the sign out is not yet complete
         signOutInfo: null,
         // Sign Out Time.
         signOutTime: null,
      };

      this.onDelayChange = this.onDelayChange.bind(this);
      this.cancelDelayCreation = this.cancelDelayCreation.bind(this);
      this.handleCreateOrUpdateDelay = this.handleCreateOrUpdateDelay.bind(this);


   }

   /**
    * Function that sets the delayPiece and the action status for the delay
    * @param {string} action - create/update/delete
    * @param {object} piece - piece for which to do a delay action
    */
   onDelayChange(action, piece) {
      if (action === "delete") {
         // Afficher le loading de pièces pour empêcher l'utilisateur de cliquer plusieurs
         // et de cliquer sur d'autres boutons affectant les pièces.
         this.setState({ displayPiecesLoading: true }, () => {
            this.deletePieceDelay(piece);
         });
      } else {
         this.setState({ delayPiece: piece }, () => {
            // Il faut changer la route pour que le timer du "KioskFooter" soit initialisé avec la bonne valeur...
            this.props.history.push("/signout/delay");
         });
      }
   }

   /**
    * Function that update the piece for the updated delay
    * @param {object} delay - the new delay value
    */
   handleCreateOrUpdateDelay(delay) {
      // Il faut changer la route pour que le timer du "KioskFooter" soit initialisé avec la bonne valeur...
      this.props.history.push("/signout");
      this.updatePieceDelayState(this.state.delayPiece, delay);
   }

   /**
    * Met à jour une pièce dans le state en lui appliquant le délai en param
    * @param {object} piece - La pièce à mettre à jour
    * @param {object} delay - Le délai à appliquer sur la pièce
    */
   updatePieceDelayState(piece, delay) {
      this.setState((prevState) => {
         // Mise à jour de la piece dans le state pour mettre à jour son délai
         const workPieces = prevState.signOutInfo.SignOutInformation.WorkPiecesSinceLastSignOut;
         const workPieceToUpdateIndex = workPieces.findIndex(
            (p) => p.Key.PieceKey.InternalNumber === piece.Key.PieceKey.InternalNumber
         );

         workPieces[workPieceToUpdateIndex] = {
            ...workPieces[workPieceToUpdateIndex],
            ServiceDelay: delay,
         };

         return {
            signOutInfo: {
               ...prevState.signOutInfo,
               SignOutInformation: {
                  ...prevState.signOutInfo.SignOutInformation,
                  // On créé un nouveau tableau pour que le composant <Pieces/> détecte le changement
                  WorkPiecesSinceLastSignOut: [...workPieces],
               },
            },
            // Suppression du state du délai en cours de création/édition
            delayPiece: null,
            // Cacher le loading de pièces affichés pour empêcher plusieurs clics.
            displayPiecesLoading: false,
         };
      });
   }

   /**
    * Supprime le délai appliqué à la pièce en param
    * @param {object} workPiece - Piece pour laquelle supprimer le délai
    */
   deletePieceDelay(workPiece) {
      const emptyDelay = { ReasonIdentifier: "", Duration: "PT0S", OvertimeCodeIdentifier: "" };
      httpPostWithAccessToken(
         "/OperationDailyServiceRest/DeleteServiceDelay",
         {
            ...emptyDelay,
            EmployeeIdentifier: this.props.employeeId,
            DailyPieceKey: workPiece.Key,
            Comment: "",
         },
         { signal: this.props.abortControllerSignal },
         this.context,
         { 
            actions, 
            authentication: this.props.stateAuthentication, 
            configurations: this.props.stateConfigurations,
            signConfig: this.props.signConfig
         }
      ).then(() => {
         this.updatePieceDelayState(workPiece, null);
      });
   }

   /**
    * Callback lors de l'annulation de la création/edition d'un délai
    */
   cancelDelayCreation() {
      // Il faut changer la route pour que le timer du "KioskFooter" soit initialisé avec la bonne valeur...
      this.props.history.push("/signout");
      this.setState({
         delayPiece: null,
      });
   }

   async componentDidMount() {
      const response = await httpPostWithAccessToken(
         "/OperationEmployeeServiceRest/SignOutAndGetInformation",
         {
            EmployeeIdentifier: this.props.employeeId,
            SignDateTime: this.props.loginTime,
            SignSiteIdentifier: this.props.signInSiteIdentifier,
            SourceOfSign: "Sign",
         },
         { signal: this.props.abortControllerSignal },
         this.context,
         {
            actions, 
            authentication: this.props.stateAuthentication, 
            configurations: this.props.stateConfigurations,
            signConfig: this.props.signConfig
         }
      );

      if (
         response.SignOutInvalidCode &&
         response.SignOutInvalidCode !== "AlreadySignedOut" &&
         response.SignOutInvalidCode !== "SignOutInfo"
      ) {
         actions.setErrorMessage({ errorMessage: response.SignOutInvalidMessage });
      } else {

         if (response.SignOutInvalidCode === "AlreadySignedOut" || response.SignOutInvalidCode === "SignOutInfo") {
            actions.setHeaderMessage(response.SignOutInvalidMessage);
         }

         this.setState({
            signOutInfo: response,
            displayLoading: false,
            signOutTime: response.LastSignOutTime || this.props.loginTime,
         });
      }
   }

   render() {
      return (
         <Fragment>
            <Loading isFullScreen isDisplayed={ this.state.displayLoading }>
               { !this.state.delayPiece && (
                  <div className="sign-out__content">
                     <div className="sign-out__scroll sign-out__scroll-pieces">
                        <div className="sign-out__piece-section">
                           <Loading isDisplayed={ this.state.displayPiecesLoading }>
                              <div className="sign-out__title-section">
                                 <div className="sign-out__title-section-left sign-out__title-section--sign-in">
                                    <Icon
                                       path={ checkboxCircleWhiteIcon }
                                       color="color-primary"
                                       className="sign-out__title-section-icon"
                                    />
                                    <span>
                                       { this.context.localizeValue("SignOutPageSIG.signInTimeTitle", [
                                          /*time:*/ this.state.signOutInfo && this.state.signOutInfo.SignInDateTime
                                             ? TimeConverter.formatISODateStringToShortTimeString(
                                                this.state.signOutInfo.SignInDateTime,
                                                this.context.localizeValue
                                             )
                                             : "",
                                       ]) }
                                    </span>
                                 </div>
                              </div>

                              <div className="sign-out__grey-line">
                                 <div className="sign-out__pieces">
                                    <Pieces
                                       displayContext="SignOut"
                                       listData={
                                          this.state.signOutInfo && this.state.signOutInfo.SignOutInformation
                                             ? this.state.signOutInfo.SignOutInformation.WorkPiecesSinceLastSignOut
                                             : []
                                       }
                                       onDelayChange={ this.onDelayChange }
                                    />
                                 </div>
                              </div>

                              <div className="sign-out__title-section">
                                 <div className="sign-out__title-section-left sign-out__title-section--sign-out">
                                    <Icon
                                       path={ checkedIcon }
                                       color="color-primary"
                                       className="sign-out__title-section-icon"
                                    />
                                    <span>
                                       { this.context.localizeValue("SignOutPageSIG.signOutTimeTitle", [
                                          /*time:*/ TimeConverter.formatISODateStringToShortTimeString(
                                             this.state.signOutTime,
                                             this.context.localizeValue
                                          ),
                                       ]) }
                                    </span>
                                 </div>
                              </div>
                           </Loading>
                        </div>
                     </div>
                     <EmployeeMessages className="sign-out__employee-message" />
                  </div>
               ) }
               { this.state.delayPiece && (
                  <Delay
                     confirmCallback={ this.handleCreateOrUpdateDelay }
                     cancelCallback={ this.cancelDelayCreation }
                     delayPiece={ this.state.delayPiece }
                  />
               ) }
            </Loading>
         </Fragment>
      );
   }
}

SignOutPage.contextType = TranslationContext;

export default connect((state) => ({
   abortControllerSignal: state.application.abortController.signal,
   employeeId: state.employeeInfo.Identifier,
   loginTime: state.authentication.loginTime,
   signInSiteIdentifier: state.configurations.data.SignInSiteIdentifier,
   signOutLateRedirectionMode: state.configurations.data.SignOutLateRedirectionMode,
   stateConfigurations: state.configurations,
   stateAuthentication: state.authentication,
   signConfig: state.signConfig
}))(SignOutPage);
