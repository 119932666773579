import createStore from "react-waterfall";

import Actions from "./actions";
import { initialState } from "./initialState.js";

/**
 * Data store configuration.
 */
const config = {
   initialState,
   actionsCreators: Actions
};

export const { Provider, connect, actions, subscribe, unsubscribe, getState } = createStore(config);
