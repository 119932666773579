/**
 * Keeps the context in a global object.
 */
const Context = {
   /**
    * Indicates if the application context is web only.
    * @returns {boolean} - true if the application context is web only,
    * false if the application is executed in the kiosk.
    */
   isWebOnly: () => {
      return typeof CefSharp === "undefined";
   }
};

export default Context;
