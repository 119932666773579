import React, { useContext, Fragment } from "react";
import PropTypes from "prop-types";
import ClassNames from "classnames";

import { Icon, List, Button, TimeConverter, Translation, Duration, TranslationContext } from "giro-react-toolkit";

import { connect } from "store";

import "./Pieces.scss";
import circleIcon from "images/circle-sign-in.svg";

/**
 * List of pieces displayed in the sigIn/signOut/delay pages.
 */
const Pieces = props => {
   const translationContext = useContext(TranslationContext);

   const canEmployeeManageServiceDelay = (props.isDelayCreationEnable && props.canEmployeeManageServiceDelays);

   /**
    * Passes the selected piece to create/update/delete a delay.
    *
    * @param {string} actionType - Type of action to do with the piece.
    * @param {Object} piece - Piece.
    */
   const onDelayButtonClick = (actionType, piece) => {
      return () => {
         props.onDelayChange(actionType, piece);
      };
   };

   /**
    * Retourne le JSX pour les infos de delay et les boutons d'action.
    *
    * @param {Object} piece - Pièce.
    */
   const generatePieceDelay = piece => {
      if (props.displayContext !== "SignOut") {
         return null;
      }

      return (
         <div className="piece__delay">
            { piece.ServiceDelay ? (
               <div className="piece__field piece__delay--has-delay">
                  <div className="piece__delay-description">
                     <div className="piece__delay-property">
                        <div className="piece__field--title">
                           <span>
                              <Translation resourceKey="PiecesSIG.signOutPieces.delay" />
                           </span>
                        </div>
                        <div className="piece__field--data">
                           <span>{ Duration.formatISODuration(piece.ServiceDelay.Duration) }</span>
                        </div>
                     </div>
                     <div className="piece__field--data piece__delay-reason">
                        <span>{ piece.ServiceDelay.Reason.Description }</span>
                     </div>
                  </div>
                  { (canEmployeeManageServiceDelay) && (
                     <Fragment>
                        <Button className="piece__delay-button" onClick={ onDelayButtonClick("update", piece) }>
                           <Translation resourceKey="PiecesSIG.signOutPieces.editButtonLabel" />
                        </Button>
                        <Button
                           className="piece__delay-button"
                           accent="secondary"
                           onClick={ onDelayButtonClick("delete", piece) }
                        >
                           <Translation resourceKey="PiecesSIG.signOutPieces.clearButtonLabel" />
                        </Button>
                     </Fragment>
                  ) }
               </div>
            ) : (
               (canEmployeeManageServiceDelay) && (
                  <Button className="piece__delay-button" onClick={ onDelayButtonClick("create", piece) }>
                     <Translation resourceKey="PiecesSIG.signOutPieces.addDelayButtonLabel" />
                  </Button>
               )
            ) }
         </div>
      );
   };

   /**
    * Retourne le JSX pour un champ de données.
    *
    * @param {Object} fieldData - Données du champ.
    * @param {number} index - Index du champ à traiter.
    */
   const generatePieceField = (fieldData, index) => {
      const fieldName = fieldData.field.Name;
      const pieceFieldClassName = ClassNames(
         "piece__field",
         `piece__field-${fieldName.replace(".", "_")}`,
         `piece__field--pos-${index + 1}`
      );

      const dataClassName = ClassNames("piece__field--data", {
         //Ajout d'une classe css pour les champs qui n'ont pas de title
         "piece__field--data-no-title": !fieldData.field.Title
      });

      return (
         <div className={ pieceFieldClassName } key={ fieldName }>
            <div className="piece__field--title">
               <span>{ fieldData.field.Title }</span>
            </div>
            <div className={ dataClassName }>
               <span>{ fieldData.value }</span>
            </div>
         </div>
      );
   };

   /**
    * Retourne le JSX pour afficher une pièce.
    *
    * @param {Object} data - Données de la pièce.
    */
   const generatePieceItem = data => {
      // On génère 2 lignes de données.
      const dataRows = ["Row1", "Row2"].map(row =>
         props.fieldsets[props.displayContext + row].map(field => ({
            field,
            value: getFormattedValue(getNestedObjectAttributeValue(data, field.Name), field.FormatType)
         }))
      );

      return (
         <div className="piece">
            <div className="piece__field piece__field-img">
               <Icon path={ circleIcon } color="white" className="piece__field-icon" />
               <div className="piece__field-dots" />
               <Icon path={ circleIcon } color="white" className="piece__field-icon" />
            </div>
            <div className="piece__rows">
               { dataRows.map((dataRow, index) => {
                  return (
                     <div className="piece__row" key={ index }>
                        { dataRow.map(generatePieceField) }
                     </div>
                  );
               }) }
            </div>
            { generatePieceDelay(data) }
         </div>
      );
   };

   /**
    * Formatte la valeur en fonction du type de format.
    *
    * @param {string} value - Valeur à formater.
    * @param {number} formatType - Le type du format à appliquer.
    */
   const getFormattedValue = (value, formatType) => {
      switch (formatType) {
         // duration
         case 1:
            return Duration.formatISODuration(value);
         // time
         case 3:
            return TimeConverter.formatISODateStringToShortTimeString(value, translationContext.localizeValue);
         default:
            return value;
      }
   };

   /**
    * Retourne la valeur d'une (sous-)propriété d'un objet.
    *
    * @param {Object} nestedObj - Objet à parcourir pour trouver la valeur.
    * @param {string} pathString - Chemin vers la propriété ("Piece.StartTime" ou "Piece.a.b" par exemple).
    */
   const getNestedObjectAttributeValue = (nestedObj, pathString) => {
      const pathArr = pathString.split(".");
      return pathArr.reduce((obj, key) => {
         return obj && obj[key] !== undefined ? obj[key] : undefined;
      }, nestedObj);
   };

   return (
      <List
         listData={ props.listData }
         renderItem={ generatePieceItem }
         className={ ClassNames("list-pieces", props.className, `list-pieces--${props.displayContext}`) }
      />
   );
};

Pieces.propTypes = {
   /** CSS classnames added to the component */
   className: PropTypes.string,
   /** How to display the pieces */
   displayContext: PropTypes.oneOf(["SignIn", "SignOut", "SignOutDelay"]).isRequired,
   /** List of pieces to be displayed */
   listData: PropTypes.arrayOf(PropTypes.object),
   /** Function that passes the specified piece for the delay */
   onDelayChange: PropTypes.func
};

export default connect(state => ({
   abortControllerSignal: state.application.abortController.signal,
   canEmployeeManageServiceDelays: state.canEmployeeManageServiceDelays,
   isDelayCreationEnable: state.configurations.data.SignOutEnableDelayCreation,
   fieldsets: state.fieldsets.data
}))(Pieces);
