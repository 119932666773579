import React, { PureComponent } from "react";
import PropTypes from "prop-types";

import InputSpinner from "../InputSpinner";
import { TimeConverter, Translation, TranslationConsumer } from "giro-react-toolkit";

import "./DelayDuration.scss";

/**
 * Delay duration section of the delay page. Employee can select the delay
 * duration using the InputSpinners and the resulting end time is updated accordingly.
 */
class DelayDuration extends PureComponent {
   constructor(props) {
      super(props);

      this.state = {
         // Duration of the delay in digits
         hour: this.props.delayDuration.getRemainingHours(),
         minuteMultipleOfTen: Math.floor(this.props.delayDuration.getRemainingMinutes() / 10),
         minute: this.props.delayDuration.minutes % 10,
         // Duration of service delay
         duration: this.props.delayDuration,
         resultingEndTime: new Date(this.props.endTime),
         // Resulting end time with the delay
         ...this.getResultingEndTime(
            this.props.delayDuration.getRemainingHours(), 
            this.props.delayDuration.getRemainingMinutes())
      };
      this.getResultingEndTime = this.getResultingEndTime.bind(this);
      this.setHourValue = this.setHourValue.bind(this);
      this.setMinuteMultipleOfTenValue = this.setMinuteMultipleOfTenValue.bind(this);
      this.setMinuteValue = this.setMinuteValue.bind(this);
   }

   /**
    * Updates the new minutes value of the duration based on the previous value on an hour change
    * @param {number} - value of the InputSpinner (hour digit)
    */
   updateMinutesValueOnHourChange(value, prevState) {
                             // Represent the delta of hours
      const durationDeltaHours = value - prevState.hour;
                             // Represent the previous state minutes (includes hours + min)
      const previousDurationMinutes = prevState.duration.minutes;
                             // Applies the hour delta to the previous duration minutes
      return durationDeltaHours * 60 + previousDurationMinutes;
   }

   /**
    * Updates the hour digit state and the duration
    * Calculates/updates the resulting end time and passes the new formatted duration to the parent
    * @param {number} - value of the InputSpinner (hour digit)
    */
   setHourValue(value) {
      this.setState(prevState => {
         const newDuration = {
            ...prevState.duration,
            hours: value,
            minutes: this.updateMinutesValueOnHourChange(value, prevState)
         };
         if (this.props.onChange) {
            this.props.onChange(newDuration);
         }
         return {
            duration: newDuration,
            hour: value,
            ...this.getResultingEndTime(value, prevState.minuteMultipleOfTen.toString() + prevState.minute.toString())
         };
      });
   }

   /**
    * Updates the new minutes value of the duration based on the previous value on an multiple of
    * 10 minutes change
    * @param {number} - value of the InputSpinner (minute*10 digit)
    */
   updateMinutesValueOnMultipleOf10MinutesChange(value, prevState) {
                             // Represent the minutes created from the value of the multiple 10 and
                             // the single digit minutes
      const durationMinutes = value * 10 + prevState.minute;
                             // Represent the previous state hours
      const durationHours = prevState.hour;
                             // Applies the hour delta to the previous duration minutes
      return durationHours * 60 + durationMinutes;
   }

   /**
    * Updates the minute (multiple of 10) digit state and the duration
    * Calculates/updates the resulting end time and passes the new formatted duration to the parent
    * @param {number} - value of the InputSpinner (minute*10 digit)
    */
   setMinuteMultipleOfTenValue(value) {
      this.setState(prevState => {
         const newDuration = {
            ...prevState.duration,
            minutes: this.updateMinutesValueOnMultipleOf10MinutesChange(value, prevState)
         };
         if (this.props.onChange) {
            this.props.onChange(newDuration);
         }
         return {
            duration: newDuration,
            minuteMultipleOfTen: value,
            ...this.getResultingEndTime(prevState.hour.toString(), value + prevState.minute.toString())
         };
      });
   }

   /**
    * Updates the new minutes value of the duration based on the previous value on an multiple of
    * single digit minutes change
    * @param {number} - value of the InputSpinner (minute digit)
    */
   updateMinutesValueOnMinutesChange(value, prevState) {
                             // Represent the minutes created from the value of the multiple 10 and
                             // the single digit minutes
      const durationMinutes = prevState.minuteMultipleOfTen * 10 + value;
                             // Represent the previous state hours
      const durationHours = prevState.hour;
                             // Applies the hour delta to the previous duration minutes
      return durationHours * 60 + durationMinutes;
   }

   /**
    * Updates the minute digit state and the duration
    * Calculates/updates the resulting end time and passes the new formatted duration to the parent
    * @param {number} - value of the InputSpinner (minute digit)
    */
   setMinuteValue(value) {
      this.setState(prevState => {
         const newDuration = {
            ...prevState.duration,
            minutes: this.updateMinutesValueOnMinutesChange(value, prevState)
         };
         if (this.props.onChange) {
            this.props.onChange(newDuration);
         }
         return {
            duration: newDuration,
            minute: value,
            ...this.getResultingEndTime(prevState.hour.toString(), prevState.minuteMultipleOfTen.toString() + value)
         };
      });
   }

   /**
    * Calculates the resulting end time with the given duration
    * then, returns the resultingEndTime for the state in the proper format
    * @param {string} durationHours
    * @param {string} durationMinutes
    */
   getResultingEndTime(durationHours, durationMinutes) {
      // Convert delay duration in minutes
      const delayInMinutes = parseInt(durationHours, 10) * 60 + parseInt(durationMinutes, 10);

      const endTime = new Date(this.props.endTime);
      endTime.setUTCMinutes(endTime.getUTCMinutes() + delayInMinutes);

      // Returns the resulting end time.
      return { resultingEndTime: endTime };
   }

   render() {
      return (
         <TranslationConsumer>
            { ({ localizeValue }) => (
               <div className="delay-duration">
                  <InputSpinner
                     className="delay-duration__spinner"
                     onChange={ this.setHourValue }
                     initialValue={ this.state.hour }
                  />
                  <div className="delay-duration__h">
                     <Translation resourceKey="DelayDurationSIG.hourSymbolText" />
                  </div>
                  <InputSpinner
                     className="delay-duration__spinner"
                     onChange={ this.setMinuteMultipleOfTenValue }
                     initialValue={ this.state.minuteMultipleOfTen }
                     max={ 5 }
                  />
                  <InputSpinner
                     className="delay-duration__spinner"
                     onChange={ this.setMinuteValue }
                     initialValue={ this.state.minute }
                  />
                  <div className="delay-duration__result">
                     <div className="delay-duration__result-title">
                        <Translation resourceKey="DelayDurationSIG.resultingEndTimeLabel" />
                     </div>
                     <div className="delay-duration__result-time">
                        { TimeConverter.formatISODateStringToShortTimeString(
                           this.state.resultingEndTime,
                           localizeValue) }
                     </div>
                  </div>
               </div>
            ) }
         </TranslationConsumer>
      );
   }
}

DelayDuration.propTypes = {
   /** End time of the Piece of the specified delay */
   endTime: PropTypes.string,
   /** Initial duration of the delay */
   delayDuration: PropTypes.object,
   /** Function that passes the duration to the parent component */
   onChange: PropTypes.func
};

export default DelayDuration;
