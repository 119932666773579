/**
 * Retourne la hauteur d'un élément incluant les marges en haut et en bas.
 * @param {object} element - l'élément à considérer.
 */
export function getElementHeightWithMargins(element) {
   // Pour les calculs des hauteurs des éléments, il faut toujours prendre offsetHeight
   // et ajouter les marges que offsetHeight ne considère pas.
   // Il est à noter que offsetHeight considère le padding et les bordures.
   let styles = window.getComputedStyle(element);
   let marginTop = getPropertyValueAsInteger(styles, "margin-top");
   let marginBottom = getPropertyValueAsInteger(styles, "margin-bottom");
   let elementHeightWithMargins = element.offsetHeight + marginTop + marginBottom;
   return elementHeightWithMargins;
}

/**
 * Retourne la valeur en int d'une propriété de style. Retourne 0 lorsque la propriété n'est pas
 * définie.
 * @param {object} styles - styles de l'élément.
 * @param {string} propertyName - nom de la propriété de style.
 */
export function getPropertyValueAsInteger(styles, propertyName) {
   let propertyValue = styles.getPropertyValue(propertyName);
   if (propertyValue && propertyValue.length) {
      return parseInt(propertyValue, 10);
   }

   return 0;
}
