import React, { PureComponent } from "react";

import { Error } from "giro-kiosk-base";
import { TranslationContext } from "giro-react-toolkit";
import { actions, connect } from "store";
import { TRACE_LEVEL } from "UtilityFunctions";

/**
 * General Sign error handler component.
 * https://reactjs.org/docs/error-boundaries.html
 */
class SignErrorBoundary extends PureComponent {
   constructor(props) {
      super(props);
      this.state = { hasError: false };

      this.onCloseError = this.onCloseError.bind(this);
   }

   componentDidCatch(error, info) {
      // We abort all fetches in progress.
      this.props.abortController.abort();

      this.setState({ hasError: true });

      // trace & console.log the error
      actions.trace(TRACE_LEVEL.error, error + info);
   }

   async onCloseError() {
      if (this.props.errorMessageWillLogOut) {
         await actions.logOut();
      }

      await actions.clearErrorMessage();

      this.setState({ hasError: false });
   }

   render() {
      if (this.state.hasError) {
         const errorMessage =
            this.props.errorMessage || this.context.localizeValue("SignErrorBoundarySIG.jsErrorTitle");
         return <Error errorMessage={ errorMessage } onClose={ this.onCloseError } redirectToRoot={ false } />;
      }

      return this.props.children;
   }
}

SignErrorBoundary.contextType = TranslationContext;

export default connect((state) => ({
   abortController: state.application.abortController,
   errorMessageWillLogOut: state.application.errorMessageWillLogOut,
   errorMessage: state.application.errorMessage,
}))(SignErrorBoundary);
