import { getGuid } from "giro-react-toolkit";

/**
 * Defines the available trace levels for console logging.
 */
export const TRACE_LEVEL = {
   error: { tag: "error", value: 0 },
   warn: { tag: "warn", value: 1 },
   info: { tag: "info", value: 2 },
   log: { tag: "log", value: 3 },
};

/**
 * set the session id used by trace
 */
export const SESSION_ID = getGuid();
