import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import ClassNames from "classnames";
import { actions } from "store";

import { Checkbox, Icon, Loading, Translation, TranslationContext } from "giro-react-toolkit";

import { getElementHeightWithMargins, httpPostWithAccessToken } from "UtilityFunctions";

import "./EmployeeMessages.scss";
import envelopeIcon from "images/envelope-icon.svg";
import { connect } from "store";

/**
 * Displays all the employee messages in order of acknowledge property.
 * In non-interactive mode, all the displayed messages are set to acknowledged automatically.
 */
class EmployeeMessages extends PureComponent {
   constructor(props) {
      super(props);
      // Calculer le nombre de messages non réceptionnés.
      let unreadMessageCount = 0;
      if (this.props.employeeMessages) {
         this.props.employeeMessages.forEach(function(msg) {
            if (!msg.MessageHasBeenAcknowledged) {
               ++unreadMessageCount;
            }
            msg.processingAcknowledgement = false;
         });
      }

      let listData = this.sortByAck(this.props.employeeMessages);
      let listDataRef = [];
      if (listData) {
         for (let i = 0; i < listData.length; i++) {
            listDataRef.push(React.createRef());
         }
      }
      this.sectionRef = React.createRef();
      this.listRef = React.createRef();
      this.overflowMessageRef = React.createRef();

      // Acknowledge message interval identifier.
      this.acknowledgeIntervalID = null;

      this.state = {
         // Sorted (by acknowledge) employee messages list.
         listData: this.sortByAck(this.props.employeeMessages),
         // List data references.
         listDataRef: listDataRef,
         // Indique un overflow. Uniquement utilisée en mode non interactif.
         overflow: false,
         // Indique le nombre de messages d'employé non réceptionnés.
         unreadMessageCount: unreadMessageCount
      };
   }

   componentDidMount() {
      let overflow = this.hasOverflow();

      if (this.props.employeeMessageAcknowledgmentMode.toLowerCase() === "manual") {
         this.setState({
            overflow: overflow
         });
         return;
      }

      this.acknowledgeIntervalID = null;
      if (this.props.isInteractiveMode && this.state.listData && this.state.listData.length) {
         // Accuser la réception des messages visibles à chaque deux secondes en mode interactif
         // comme l'utilisateur peut défiler les messages verticalement.
         this.acknowledgeIntervalID = setInterval(() => this.acknowledgeVisibleEmployeeMessages(), 2000);
      }

      this.setState(
         {
            overflow: overflow
         },
         () => {
            // Pour le mode d'accusation de réception automatique, accuser la réception des messages
            // d'employé visibles.
            if (this.state.listData && this.state.listData.length) {
               this.acknowledgeVisibleEmployeeMessages();
            }
         }
      );
   }

   componentDidUpdate(prevProps) {
      if (this.props.listData !== prevProps.listData) {
         let listData = this.sortByAck(this.props.listData);
         let listDataRef = [];
         if (listData) {
            for (let i = 0; i < listData.length; i++) {
               listDataRef.push(React.createRef());
            }
         }

         this.setState(
            {
               listData: this.props.listData,
               listDataRef: listDataRef
            },
            () => {
               let overflow = this.hasOverflow();
               this.setState({ overflow: overflow });
            }
         );
      }
   }

   componentWillUnmount() {
      clearInterval(this.acknowledgeIntervalID);
   }

   acknowledgeVisibleEmployeeMessages() {
      // Vérifier si la composante n'a pas été unmount.
      if (!this.sectionRef.current) {
         return;
      }
      // Pour le mode d'accusation de réception automatique, accuser la réception des messages
      // d'employé visibles.
      let visibleMessagesToAcknowledgeArray = [];
      let visibleMessagesToAcknowledgeIndexes = this.getCompletelyVisibleMessagesToAcknowledgeIndexes();
      for (let i = 0; i < visibleMessagesToAcknowledgeIndexes.length; i++) {
         let messageIndex = visibleMessagesToAcknowledgeIndexes[i];
         let messageData = this.state.listData[messageIndex];
         visibleMessagesToAcknowledgeArray.push(messageData.Key);
      }
      if (visibleMessagesToAcknowledgeArray && visibleMessagesToAcknowledgeArray.length > 0) {
         const acknowledgeEmployeeMessagesPromise = () => {
            return httpPostWithAccessToken(
               "/OperationEmployeeServiceRest/AcknowledgeEmployeeMessages",
               {
                  EmployeeIdentifier: this.props.employeeId,
                  EmployeeMessagesToAcknowledgeArray: visibleMessagesToAcknowledgeArray
               },
               { signal: this.props.abortControllerSignal },
               this.context,
               {
                  actions,
                  authentication: this.props.stateAuthentication,
                  configurations: this.props.stateConfigurations,
                  signConfig: this.props.signConfig
               }
            );
         };
         acknowledgeEmployeeMessagesPromise().then(() => {
            // Vérifier si la composante n'a pas été unmount.
            if (!this.sectionRef.current) {
               return;
            }
            // Mettre à jour les messages dont la réception a été accusée.
            let updatedListData = this.state.listData;
            for (let i = 0; i < visibleMessagesToAcknowledgeIndexes.length; i++) {
               updatedListData[visibleMessagesToAcknowledgeIndexes[i]].MessageHasBeenAcknowledged = true;
            }
            this.setState({ listData: updatedListData }, () => {
               if (!this.props.isSignInInteractive) {
                  return;
               }
               // Vérifier s'il reste des messages dont la réception n'a pas été accusée.
               let allMessagesHaveBeenAcknowledged = true;
               for (let i = 0; i < this.state.listData.length; i++) {
                  if (!this.state.listData[i].MessageHasBeenAcknowledged) {
                     allMessagesHaveBeenAcknowledged = false;
                     break;
                  }
               }
               if (allMessagesHaveBeenAcknowledged) {
                  // Retirer l'intervalle pour éviter des appels inutiles.
                  clearInterval(this.acknowledgeIntervalID);
               }
            });
         });
      }
   }

   /**
    * Generates the employee message list.
    */
   generateList() {
      const contentClassName = ClassNames("employee-message-section__content", {
         "employee-message-section__content--scrollable": this.props.isSignInInteractive
      });
      return (
         <Fragment>
            <div className="employee-message-section__title">
               <Translation
                  resourceKey="EmployeeMessagesSIG.title"
                  params={ [/*count:*/ this.state.unreadMessageCount] }
               />
            </div>
            <div className={ contentClassName }>
               <ul className="employee-message-list" ref={ this.listRef }>
                  { this.generateListItems() }
               </ul>
            </div>
         </Fragment>
      );
   }

   /**
    * Generates the employee message list items.
    */
   generateListItems() {
      let displayCheckBoxes =
         this.props.isSignInInteractive && this.props.employeeMessageAcknowledgmentMode.toLowerCase() === "manual";
      return this.state.listData.map((data, i) => {
         const messageClassName = ClassNames("employee-message", {
            "employee-message--read": data.MessageHasBeenAcknowledged
         });
         return (
            <li className={ messageClassName } key={ i } ref={ this.state.listDataRef[i] }>
               { displayCheckBoxes && (
                  <Loading
                     isFullScreen={ false }
                     isDisplayed={ data.processingAcknowledgement }
                     scale="small">
                     <Checkbox
                        label={ data.MessageText }
                        value={ data.MessageHasBeenAcknowledged }
                        className="employee-message__acknowledge-checkbox"
                        disabled={ data.MessageHasBeenAcknowledged }
                        onChange={ () => {
                           if (this.props.isSignInInteractive) {
                              this.onCheckboxChange(i);
                           }
                        } }
                     />
                  </Loading>
               ) }
               { !displayCheckBoxes && <span>{ data.MessageText }</span> }
            </li>
         );
      });
   }

   /**
    * Get the indexes on the completely visible messages not acknowledged yet.
    */
   getCompletelyVisibleMessagesToAcknowledgeIndexes() {
      if (!this.listRef.current) {
         return [];
      }

      let heightAvailable = this.listRef.current.clientHeight;
      if (!this.props.isSignInInteractive && this.state.overflow) {
         // Retirer la hauteur du message d'avertissement comme les messages en-dessus ne sont pas
         // visibles.
         heightAvailable -= this.overflowMessageRef.current.offsetHeight;
      }

      let minYPosition = this.listRef.current.scrollTop;
      let maxYPosition = minYPosition + heightAvailable;

      let visibleMessagesToAcknowledgeIndexes = [];
      for (let i = 0; i < this.state.listDataRef.length; i++) {
         let messageElement = this.state.listDataRef[i];
         let messageElementYPosition = messageElement.current.offsetTop;

         if (messageElementYPosition < minYPosition) {
            // L'élément est avant la position minimale affichée et n'est donc pas complètement visible.
            continue;
         }

         let messageElementHeightWithMargins = getElementHeightWithMargins(messageElement.current);
         if (messageElementYPosition + messageElementHeightWithMargins > maxYPosition) {
            // L'élément dépasse la position maximale affichée et n'est donc pas complètement visible.
            // Tous les autres éléments qui suivent ne sont donc pas complètement visibles aussi.
            break;
         }

         // Le message est complètement visible et sa réception doit être accusée.
         if (!this.state.listData[i].MessageHasBeenAcknowledged) {
            visibleMessagesToAcknowledgeIndexes.push(i);
         }
      }
      return visibleMessagesToAcknowledgeIndexes;
   }

   /**
    * Check if the list has an overflow.
    */
   hasOverflow() {
      if (!this.listRef.current) {
         return false;
      }

      let heightAvailable = this.listRef.current.clientHeight;
      let overflow = false;
      for (let i = 0; i < this.state.listDataRef.length; i++) {
         let currentMessageHeightWithMargins = getElementHeightWithMargins(this.state.listDataRef[i].current);
         if (currentMessageHeightWithMargins < heightAvailable) {
            overflow = true;
            break;
         }
         heightAvailable -= currentMessageHeightWithMargins;
      }
      return overflow;
   }

   /**
    * On check of a message, its ack property is updated, the message count is updated
    * and the message identifier is added to the list of messages to acknowledge sent to the server
    */
   onCheckboxChange(i) {
      let currEmpMessages = this.state.listData;
      const messageToAcknowledgeKey = currEmpMessages[i].Key;
      currEmpMessages[i].processingAcknowledgement = true;

      // By copying the array into a new one, the object reference changes, thus forcing the state to update.
      this.setState(state => ({ ...state, listData: [...currEmpMessages] }))

      const acknowledgeEmployeeMessagesPromise = () => {
         return httpPostWithAccessToken(
            "/OperationEmployeeServiceRest/AcknowledgeEmployeeMessages",
            {
               EmployeeIdentifier: this.props.employeeId,
               EmployeeMessagesToAcknowledgeArray: [messageToAcknowledgeKey]
            },
            { signal: this.props.abortControllerSignal },
            this.context,
            { 
               actions, 
               authentication: this.props.stateAuthentication, 
               configurations: this.props.stateConfigurations,
               signConfig: this.props.signConfig
            }
         );
      };
      acknowledgeEmployeeMessagesPromise().then(() => {
         // Mettre à jour le message dont la réception a été accusée.
         this.setState(prevState => {
            prevState.listData[i].MessageHasBeenAcknowledged = true;
            prevState.listData[i].processingAcknowledgement = false;
            this.updateUnreadMessageCount();
            return {
               listData: prevState.listData
            };
         });
      });
   }

   /**
    * Sorts the given array of messages by ack property
    * False comes first, True comes last
    * @returns {object[]} - the messages in order of ack property.
    */
   sortByAck(arrayOfMsg) {
      const sortedMessages = arrayOfMsg.sort(
         (msgA, msgB) => msgA.MessageHasBeenAcknowledged - msgB.MessageHasBeenAcknowledged
      );
      return sortedMessages;
   }

   /**
    * Calculates the number of unread messages left to acknowledge in interactive mode
    * @returns {number} - number of messages left to acknowledge.
    */
   updateUnreadMessageCount() {
      if (this.props.isSignInInteractive) {
         let unreadMessageCount = 0;
         this.state.listData.forEach(data => {
            if (!data.MessageHasBeenAcknowledged) {
               ++unreadMessageCount;
            }
         });
         this.setState({ unreadMessageCount: unreadMessageCount });
      }
   }

   render() {
      const isEmpty = !this.state.listData || this.state.listData.length === 0;
      const sectionClassName = ClassNames(
         "employee-message-section",
         { "employee-message-section--empty": isEmpty },
         {
            "employee-message-section--with-manual-acknowledge":
               this.props.employeeMessageAcknowledgmentMode.toLowerCase() === "manual"
         },
         this.props.className
      );

      return (
         <div className={ sectionClassName } ref={ this.sectionRef }>
            { isEmpty && (
               <span className="employee-message-section__empty-message">
                  <Translation resourceKey="EmployeeMessagesSIG.noEmployeeMessageText" />
               </span>
            ) }
            { !isEmpty && this.generateList() }
            { !this.props.isSignInInteractive && this.state.overflow && (
               <div className="employee-message-section__overflow-message" ref={ this.overflowMessageRef }>
                  <Icon path={ envelopeIcon } />
                  <span className="employee-message-section__overflow-message-text">
                     <Translation resourceKey="GlobalSIG.overflowMessagesText" />
                  </span>
               </div>
            ) }
         </div>
      );
   }
}

EmployeeMessages.contextType = TranslationContext;

EmployeeMessages.propTypes = {
   /** CSS class names added to the component. */
   className: PropTypes.string
};

export default connect(state => ({
   abortControllerSignal: state.application.abortController.signal,
   employeeId: state.employeeInfo.Identifier,
   employeeMessageAcknowledgmentMode: state.configurations.data.EmployeeMessageAcknowledgementMode,
   employeeMessages: state.employeeMessages,
   isSignInInteractive: state.configurations.data.SignInInteractiveMode,
   stateConfigurations: state.configurations,
   stateAuthentication: state.authentication,
   signConfig: state.signConfig
}))(EmployeeMessages);
