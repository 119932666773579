import React, { Fragment, PureComponent } from "react";
import PropTypes from "prop-types";
import ClassNames from "classnames";

import { Icon, Translation } from "giro-react-toolkit";

import { getElementHeightWithMargins } from "UtilityFunctions";

import "./ServiceMessages.scss";
import envelopeIcon from "images/envelope-icon.svg";
import { connect } from "store";

/**
 * Displays service messages.
 */
class ServiceMessages extends PureComponent {
   constructor(props) {
      super(props);

      let listDataRef = [];
      if (this.props.listData) {
         for (let i = 0; i < this.props.listData.length; i++) {
            listDataRef.push(React.createRef());
         }
      }

      this.state = {
         listData: this.props.listData,
         listDataRef: listDataRef,
         // Indique un overflow. Uniquement utilisée en mode non interactif.
         overflow: false
      };

      this.listRef = React.createRef();
   }

   componentDidMount() {
      let overflow = this.hasOverflow();
      this.setState({
         overflow: overflow
      });
   }

   componentDidUpdate(prevProps) {
      if (this.props.listData !== prevProps.listData) {
         let listDataRef = [];
         if (this.props.listData) {
            for (let i = 0; i < this.props.listData.length; i++) {
               listDataRef.push(React.createRef());
            }
         }

         this.setState(
            {
               listData: this.props.listData,
               listDataRef: listDataRef
            },
            () => {
               let overflow = this.hasOverflow();
               this.setState({ overflow: overflow });
            }
         );
      }
   }

   /**
    * Generates the service messages list.
    */
   generateList() {
      const contentClassName = ClassNames("service-message-section__content", {
         "service-message-section__content--scrollable": this.props.isSignInInteractive
      });
      return (
         <Fragment>
            <div className="service-message-section__title">
               <Translation resourceKey="ServiceMessagesSIG.title" params={ [/*count:*/ this.state.listData.length] } />
            </div>
            <div className={ contentClassName }>
               <ul className="service-message-list" ref={ this.listRef }>
                  { this.state.listData.map((data, i) => {
                     return (
                        <li className="service-message" key={ i } ref={ this.state.listDataRef[i] }>
                           <div className="service-message__content">
                              <div className="service-message__title">{ this.generateMessageTitle(data) }</div>
                              <div className="service-message__message"> { data.Text } </div>
                           </div>
                        </li>
                     );
                  }) }
               </ul>
            </div>
         </Fragment>
      );
   }

   /**
    * Generates route identifiers.
    * @param {object} data - service message data.
    */
   generateRouteIdentifiers(data) {
      return (
         <Fragment>
            { data.RouteIdentifiers.length > 0 && (
               <Translation
                  resourceKey="ServiceMessagesSIG.routeTitle"
                  params={ [/*routes:*/ data.RouteIdentifiers.toString()] }
               />
            ) }
         </Fragment>
      );
   }

   /**
    * Generates stop identifiers.
    * @param {object} data - service message data.
    */
   generateStopIdentifiers(data) {
      return (
         <Fragment>
            { data.StopIdentifiers.length > 0 && (
               <Translation
                  resourceKey="ServiceMessagesSIG.stopTitle"
                  params={ [/*stops:*/ data.StopIdentifiers.toString()] }
               />
            ) }
         </Fragment>
      );
   }

   /**
    * Generates the title of the specified service message.
    * @param {object} data - service message data.
    */
   generateMessageTitle(data) {
      return (
         <span>
            { data.Identifier } - { data.Type }
            { this.generateRouteIdentifiers(data) }. { this.generateStopIdentifiers(data) }
         </span>
      );
   }

   /**
    * Check if the list has an overflow.
    */
   hasOverflow() {
      if (!this.listRef.current) {
         return false;
      }

      let heightAvailable = this.listRef.current.clientHeight;
      let overflow = false;
      for (let i = 0; i < this.state.listDataRef.length; i++) {
         let currentMessageHeightWithMargins = getElementHeightWithMargins(this.state.listDataRef[i].current);
         if (currentMessageHeightWithMargins < heightAvailable) {
            overflow = true;
            break;
         }
         heightAvailable -= currentMessageHeightWithMargins;
      }
      return overflow;
   }

   render() {
      const isEmpty = !this.state.listData || this.state.listData.length === 0;
      const sectionClassName = ClassNames(
         "service-message-section",
         { "service-message-section--empty": isEmpty },
         this.props.className
      );
      return (
         <div className={ sectionClassName }>
            { isEmpty && (
               <span className="service-message-section__empty-message">
                  <Translation resourceKey="ServiceMessagesSIG.noServiceMessageText" />
               </span>
            ) }
            { !isEmpty && this.generateList() }
            { !this.props.isSignInInteractive && this.state.overflow && (
               <div className="service-message-section__overflow-message">
                  <Icon path={ envelopeIcon } />
                  <span className="service-message-section__overflow-message-text">
                     <Translation resourceKey="GlobalSIG.overflowMessagesText" />
                  </span>
               </div>
            ) }
         </div>
      );
   }
}

ServiceMessages.propTypes = {
   /** CSS class names added to the component. */
   className: PropTypes.string,
   /**List of service messages to be displayed */
   listData: PropTypes.arrayOf(PropTypes.object)
};

export default connect(state => ({
   isSignInInteractive: state.configurations.data.SignInInteractiveMode
}))(ServiceMessages);
