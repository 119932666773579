import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { Duration, Button, Loading, Translation, TranslationContext } from "giro-react-toolkit";

import DelayDuration from "./DelayDuration";
import Pieces from "Components/Pieces";
import ReasonList from "./ReasonList";
import OvertimeCodeList from "./OvertimeCodeList";
import { httpPostWithAccessToken } from "UtilityFunctions";
import { actions } from "store";

import "./Delay.scss";
import { connect } from "store";

/**
 * Delay page where the employee must select a reason and a duration to create a delay.
 */
class Delay extends Component {
   constructor(props) {
      super(props);

      let selectedReasonIdentifier = this.props.delayPiece.ServiceDelay
         ? this.props.delayPiece.ServiceDelay.Reason.Identifier
         : null;
      let delayDuration = this.props.delayPiece.ServiceDelay
         ? Duration.byISOString(this.props.delayPiece.ServiceDelay.Duration)
         : new Duration(0);
      let selectedOvertimeCodeIdentifier = this.props.delayPiece.ServiceDelay
         ? this.props.delayPiece.ServiceDelay.OvertimeCode.Identifier
         : this.props.delayOvertimeCodes[0].Identifier;

      this.state = {
         //Disable property of the confirm button
         ...this.getDisabledConfirmButtonState(delayDuration, selectedReasonIdentifier, selectedOvertimeCodeIdentifier),
         // Indicates to display a loading that completely covers the delay component content.
         displayLoading: false,
         // Duration of the service delay
         duration: delayDuration,
         // Specified reason identifier for the service delay
         selectedReasonIdentifier: selectedReasonIdentifier,
         // Specified overtime code identifier for the service delay
         selectedOvertimeCodeIdentifier: selectedOvertimeCodeIdentifier
      };
      this.onReasonChanged = this.onReasonChanged.bind(this);
      this.onDurationChanged = this.onDurationChanged.bind(this);
      this.onOvertimeCodeChanged = this.onOvertimeCodeChanged.bind(this);
      this.onClearDelayButtonClick = this.onClearDelayButtonClick.bind(this);
      this.onConfirmDelayButtonClick = this.onConfirmDelayButtonClick.bind(this);
   }

   /**
    * Returns the object to set the state for the Confirm button (disabled or not)
    * @param {object} duration The current duration of the delay
    * @param {object} reasonIdentifier The current reason identifier
    */
   getDisabledConfirmButtonState(duration, reasonIdentifier) {
      if ((duration.Hours !== 0 || duration.Minutes !== 0) && reasonIdentifier) {
         //enables the confirm button when there is a reason selected and the duration is not 0
         return { disabledConfirmButton: false };
      } else {
         //disables the confirm button otherwise
         return { disabledConfirmButton: true };
      }
   }

   /**
    * On selection of a reason, updates the selectedReason state, and disabledConfirmButton state if applicable
    * @param {string} selectedReasonIdentifier - the selected reason identifier
    */
   onReasonChanged(selectedReasonIdentifier) {
      const reason = this.props.delayReasons.find(
         currentReason => currentReason.Identifier === selectedReasonIdentifier
      );
      this.setState({
         selectedReasonIdentifier: reason.Identifier,
         //enable the confirm button if applicable
         ...this.getDisabledConfirmButtonState(this.state.duration, reason.Identifier)
      });
   }

   /**
    * Updates the duration state and disabledConfirmButton state if applicable
    * @param {object} newDuration - the updated duration
    */
   onDurationChanged(newDuration) {
      this.setState({
         //update the duration value
         duration: newDuration,
         //enable or disable confirm button
         ...this.getDisabledConfirmButtonState(newDuration, this.state.selectedReasonIdentifier)
      });
   }

   /**
    * On selection of a overtime code, updates the selectedOvertimeCodeIdentifier state,
    * and disabledConfirmButton state if applicable
    * @param {string} selectedOvertimeCodeIdentifier - the selected reason identifier
    */
   onOvertimeCodeChanged(selectedOvertimeCodeIdentifier) {
      const overtimeCode = this.props.delayOvertimeCodes.find(
         currentOvertimeCodeIdentifier => currentOvertimeCodeIdentifier.Identifier === selectedOvertimeCodeIdentifier
      );
      this.setState({
         selectedOvertimeCodeIdentifier: overtimeCode.Identifier
      });
   }

   /**
    * Callback when clicking on "clear" button to delete a service delay
    */
   onClearDelayButtonClick() {
      const emptyDelay = {
         ReasonIdentifier: "",
         Duration: "PT0S",
         OvertimeCodeIdentifier: ""
      };
      this.setState({ displayLoading: true }, () => {
         this.saveDelay("delete", this.props.delayPiece, emptyDelay).then(() => {
            if (this.props.confirmCallback) {
               this.props.confirmCallback(undefined);
            }
         });
      });
   }
   /**
    * Callback when clicking on "confirm" button to create or update a service delay
    */
   onConfirmDelayButtonClick() {
      let action = "create";
      if (this.props.delayPiece.ServiceDelay) {
         action = "update";
      }

      const delay = {
         // Les minutes d'une duration comprend les heures aussi d'où la raison
         // du modulo ici
         Duration: Duration.toISOString(this.state.duration.minutes % 60, this.state.duration.hours),
         ReasonIdentifier: this.state.selectedReasonIdentifier,
         OvertimeCodeIdentifier: this.state.selectedOvertimeCodeIdentifier
      };

      this.setState({ displayLoading: true }, () => {
         this.saveDelay(action, this.props.delayPiece, delay).then(createdorUpdatedDelay => {
            if (createdorUpdatedDelay && this.props.confirmCallback) {
               this.props.confirmCallback(createdorUpdatedDelay);
            } else {
               this.setState(prevState => ({
                  displayLoading: false,
                  duration: new Duration(prevState.duration.minutes * 60000 /*milliseconds*/, false)
               }));
            }
         });
      });
   }

   /**
    * Appelle le service pour mettre à jour un délai et retourne une promesse
    * @param {string} action - action à faire sur le délai (create, update ou delete)
    * @param {object} piece - pièce pour laquelle mettre à jour le délai
    * @param {object} delay - Information de délai à enregistrer (Duration et ReasonIdentifier)
    * @return {fetch} - La requête POST qui va faire la mise à jour
    */
   saveDelay(action, piece, delay) {
      return httpPostWithAccessToken(
         `/OperationDailyServiceRest/${action}ServiceDelay`,
         {
            ...delay,
            EmployeeIdentifier: this.props.employeeId,
            DailyPieceKey: piece.Key,
            Comment: ""
         },
         { signal: this.props.abortControllerSignal },
         this.context,
         { 
            actions, 
            authentication: this.props.stateAuthentication, 
            configurations: this.props.stateConfigurations,
            signConfig: this.props.signConfig
         }
      );
   }

   render() {
      return (
         <Fragment>
            <Loading isDisplayed={ this.state.displayLoading } isFullScreen={ true }>
               <div className="delay__content">
                  <div className="delay__piece-section">
                     <span>
                        <Translation resourceKey="DelaySIG.specifiedPieceInstructionText" />
                     </span>
                     <Pieces
                        className="delay__piece"
                        displayContext="SignOutDelay"
                        httpErrorCallback={ this.props.httpErrorCallback }
                        httpExceptionCallback={ this.props.httpExceptionCallback }
                        httpTimeoutCallback={ this.props.httpTimeoutCallback }
                        listData={ [this.props.delayPiece] }
                     />
                  </div>

                  <div className="delay__select-section">
                     <span>
                        <Translation resourceKey="DelaySIG.selectReasonAndDurationInstructionText" />
                     </span>
                     <div className="delay__input-section">
                        <div className="delay__scroll">
                           <ReasonList
                              reasonList={ this.props.delayReasons }
                              onChange={ this.onReasonChanged }
                              selectedReasonIdentifier={ this.state.selectedReasonIdentifier }
                           />
                        </div>
                        <DelayDuration
                           delayDuration={ this.state.duration }
                           endTime={ this.props.delayPiece.Piece.ClearTime }
                           onChange={ this.onDurationChanged }
                        />
                     </div>
                  </div>
                  <div className="delay__overtime-code-section">
                     <span>
                        <Translation resourceKey="DelaySIG.overtimeCodeLabel" />
                     </span>
                     <div className="delay__overtime-code-input-section">
                        <OvertimeCodeList
                           overtimeCodeList={ this.props.delayOvertimeCodes }
                           onChange={ this.onOvertimeCodeChanged }
                           selectedOvertimeCodeIdentifier={ this.state.selectedOvertimeCodeIdentifier }
                        />
                     </div>
                  </div>
                  <div className="delay__button">
                     <Button
                        scale="extra-large"
                        disabled={ this.state.disabledConfirmButton }
                        onClick={ this.onConfirmDelayButtonClick }
                     >
                        <Translation resourceKey="DelaySIG.confirmDelayButtonLabel" />
                     </Button>
                     { this.props.delayPiece.ServiceDelay ? (
                        <Button accent="secondary" scale="extra-large" onClick={ this.onClearDelayButtonClick }>
                           <Translation resourceKey="DelaySIG.clearDelayButtonLabel" />
                        </Button>
                     ) : (
                        ""
                     ) }
                     <Button accent="secondary" scale="extra-large" onClick={ this.props.cancelCallback }>
                        <Translation resourceKey="GlobalSIG.cancelButtonLabel" />
                     </Button>
                  </div>
               </div>
            </Loading>
         </Fragment>
      );
   }
}

Delay.defaultProps = {
   displayLoading: false
};

Delay.contextType = TranslationContext;

Delay.propTypes = {
   /** Function that passes (to SignOut) the the delay to create or update */
   confirmCallback: PropTypes.func,
   /** Function called when clicking to cancel button */
   cancelCallback: PropTypes.func,
   /** Specified piece of the delay */
   delayPiece: PropTypes.object
};

export default connect(state => ({
   abortControllerSignal: state.application.abortController.signal,
   delayReasons: state.delayReasons.data,
   delayOvertimeCodes: state.delayOvertimeCodes.data,
   employeeId: state.employeeInfo.Identifier,
   stateConfigurations: state.configurations,
   stateAuthentication: state.authentication,
   signConfig: state.signConfig
}))(Delay);
