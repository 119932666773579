import { initialState } from "../initialState.js";
import { httpGetWithAccessToken } from "UtilityFunctions";

/**
 * Actions relative to the authentication.
 */
const AuthenticationServiceActions = {
   /**
    * Logs in the user with a username and optionally a password
    * and sets the response token in the store.
    * Calls getGeneralConfigurations when done.
    * Calls getEmployeeRelatedInfo when done.
    *
    * @param {object} formData - Data to post in order to log in.
    */
   logIn: async ({ configurations, signConfig }, actions, { formData, translationContext }) => {
      await actions.setIsAuthenticating();

      const clientId = configurations.data.SecurityTokenServerClientId;
      const clientSecret = configurations.data.SecurityTokenServerClientSecret;
      const tokenEndPoint = configurations.data.SecurityTokenServerEndPoint;

      // Username might contain '/' or '=' characters when a card is used.
      const grantUsername = formData.fromCard ? encodeURIComponent(formData.username) : formData.username;
      const fetchOptions = {
         method: "POST",
         // prettier-ignore
         headers: {
            "Accept": "application/json",
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Basic " + window.btoa(clientId + ":" + clientSecret),
            "Cache-Control": "no-cache",
            "Credentials": "include"
         },
         body: `grant_type=password&username=${grantUsername}&password=${formData.password}&scope=OperxsrvRest`,
      };

      const httpResponse = await httpGetWithAccessToken(
         tokenEndPoint + "/connect/token",
         null /*params*/,
         fetchOptions,
         translationContext,
         { actions, configurations, signConfig },
         true /*excludeAccessToken*/
      );

      if (!httpResponse) {
         return { authentication: initialState.authentication };
      }

      await actions.setAuthentication({
         // Use ForceLoginDateTime if it's set in the configurations instead of the real login time.
         loginTime: configurations.data.General.ForceLoginDateTime || formData.loginTime,
         token: httpResponse,
         username: formData.username,
      });

      // These calls can only be done with a token.
      await Promise.all([
         // Only done once per initialization. This information doesn't change between users.
         actions.getGeneralConfigurations(translationContext),
         // Get employee related information.
         actions.getEmployeeRelatedInfo({
            fromCard: formData.fromCard,
            translationContext,
            username: formData.username,
         }),
      ]);

      await actions.setAuthenticationCompleted();
   },

   /**
    * Logs out the user and resets the state, but keeps the configurations.
    * Will automatically return to the HomePage (Login),
    * this logic is in the App.js.
    */
   logOut: ({ application, configurations, delayReasons, delayOvertimeCodes, fieldsets, signConfig }) => {
      // Cancel all fetch requests using the AbortController.
      application.abortController.abort();

      // Return the state to the initial state while keeping configurations and delay reasons.
      return {
         ...initialState,
         application: {
            ...initialState.application,
            // Must instantiate a new AbortController since initialState is static.
            abortController: new AbortController(),
            corporateMessages: application.corporateMessages,
         },
         configurations,
         delayReasons,
         delayOvertimeCodes,
         fieldsets,
         signConfig,
      };
   },

   /**
    * Sets isAuthenticating to true to show a loading on the Login screen.
    * Called by logIn.
    */
   setIsAuthenticating: async ({ authentication }) => ({
      authentication: {
         ...authentication,
         isAuthenticating: true,
      },
   }),

   /**
    * Sets the token in the store.
    * Called by logIn.
    *
    * @param {Object} token - Token to set in the store.
    * @param {Object} loginTime - Considered time for signing in and out.
    */
   setAuthentication: ({ authentication }, actions, { loginTime, token, username }) => ({
      authentication: {
         ...authentication,
         loginTime,
         token,
         username,
      },
   }),

   /**
    * Sets isAuthenticating to false and isLoggedIn to true. Used by the root to determine if the
    * login process has completed.
    */
   setAuthenticationCompleted: ({ authentication }) => ({
      authentication: {
         ...authentication,
         isLoggedIn: true,
         isAuthenticating: false,
      },
   }),
};

export default AuthenticationServiceActions;
